@import 'src/variables';

.SidebarColumn {
    display: flex;
    flex-direction: column;
    position: relative;

    @media (min-width: $media1024) {
        background: linear-gradient(0.02deg, #fbfbfb 76.02%, #f1f1f1 95.61%);
    }

    @media (min-width: $media375) {
        padding: 20px 20px 80px 20px !important;
    }

    @media screen and (min-width: $media1024) {
        width: 371px !important;
        max-width: 371px !important;
        min-width: 371px !important;
        padding: 20px 28px !important;
    }

    @media screen and (min-width: $media1440) {
        width: 428px !important;
        max-width: 428px !important;
        min-width: 428px !important;
        padding: 28px 35px !important;
    }

    @media screen and (min-width: $media1920) {
        width: 530px !important;
        max-width: 530px !important;
        min-width: 530px !important;
    }

    .Sidebar {
        display: flex;
        flex-direction: column;
        z-index: 2;

        &__Title {
            color: #636363;

            @media screen and (min-width: $media375) {
                margin-top: 34px;
                margin-left: 40px;
                font-size: 14px;
                line-height: 19px;
            }

            @media screen and (min-width: $media1024) {
                margin-top: 34px;
                margin-left: 40px;
                font-size: 11px;
                line-height: 15px;
            }

            @media screen and (min-width: $media1440) {
                margin-top: 42px;
                margin-left: 53px;
                font-size: 12px;
                line-height: 16.3px;
            }

            @media screen and (min-width: $media1920) {
                margin-top: 42px;
                margin-left: 53px;
                font-size: 12px;
                line-height: 16.3px;
            }
        }

        &__Description {
            font-weight: 400;
            color: #8c8c8c;

            @media screen and (min-width: $media375) {
                margin-left: 40px;
                margin-top: 11px;
                margin-bottom: 12px;
                font-size: 12px;
                line-height: 138.1%;
                width: 296px;
            }

            @media screen and (min-width: $media1024) {
                margin-left: 40px;
                margin-top: 11px;
                margin-bottom: 12px;
                font-size: 9px;
                line-height: 138%;
                width: 296px;
            }

            @media screen and (min-width: $media1440) {
                margin-left: 53px;
                margin-top: 5px;
                margin-bottom: 45px;
                font-size: 10px;
                line-height: 138.1%;
                width: 327px;
            }

            @media screen and (min-width: $media1920) {
                margin-left: 53px;
                margin-top: 5px;
                margin-bottom: 45px;
                font-size: 11px;
                line-height: 138.1%;
                width: 327px;
            }
        }

        .InfoBlock {
            @media screen and (min-width: $media375) {
                height: 70px;

                &:nth-child(1) {
                    width: calc(100% - 62px);
                    margin-left: 31px;
                    margin-bottom: 15px;
                    background: linear-gradient(262.84deg, #fff3e9 -27.39%, #fef5e8 71.59%);
                }

                &:nth-child(2) {
                    width: calc(50% - 38px);
                    margin-left: 31px;
                }

                &:nth-child(3) {
                    width: calc(50% - 38px);
                }
            }

            @media screen and (min-width: $media1024) {
                height: 65px;

                &:nth-child(1) {
                    width: 100%;
                    margin-left: 40px;
                    margin-bottom: 15px;
                    background: transparent;

                    .InfoBlock__description {
                        margin-top: 5px;
                    }
                }

                &:nth-child(2) {
                    width: 102px;
                    margin-left: 40px;
                }

                &:nth-child(3) {
                    width: 102px;
                }
            }

            @media screen and (min-width: $media1440) {
                height: 82px;

                &:nth-child(1) {
                    width: 172px;
                    margin-left: 35px;
                    margin-bottom: 0;
                }

                &:nth-child(2) {
                    width: 113px;
                    margin-left: 0;
                }

                &:nth-child(3) {
                    width: 129px;
                }
            }

            @media screen and (min-width: $media1920) {
                height: 97px;

                &:nth-child(1) {
                    width: 180px;
                    margin-left: 45px;
                }

                &:nth-child(2) {
                    width: 133px;
                }

                &:nth-child(3) {
                    width: 151px;
                }
            }
        }

        &__Content {
            &__Data {
                margin-top: 18px;
                padding: 15px;
                background: linear-gradient(70.27deg, #e0e0e0 7.3%, #ebebeb 90.33%);
                border-radius: $border-radius;

                .divider {
                    background-color: #d3d3d3;
                }

                > .title {
                    font-size: 13px;
                    line-height: 17px;
                    margin-bottom: 15px;
                    margin-left: 5px;
                }

                .values {
                    display: flex;

                    .balance {
                        width: 50%;
                        background: linear-gradient(0deg, #f86001 0%, #fea001 93.75%);
                        border-radius: $border-radius;
                        padding: 11px 19px;

                        .title {
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 17px;
                            color: #fff;
                            margin-bottom: 5px;
                        }

                        .value {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 22px;
                            color: #fff;
                            font-family: 'Vela Sans Regular';
                        }
                    }

                    .total-income {
                        padding: 11px 19px;

                        .title {
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 17px;
                            color: #000;
                            margin-bottom: 5px;
                        }

                        .value {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 22px;
                            color: #000;
                            font-family: 'Vela Sans Regular';
                        }
                    }
                }

                .profits {
                    display: flex;
                    // gap: 20px;

                    > div {
                        width: 33%;
                    }

                    .title {
                        font-weight: 500;

                        @media screen and (min-width: $media375) {
                            font-size: 12px;
                            line-height: 14px;
                            margin-bottom: 6px;
                        }

                        @media screen and (min-width: $media1024) {
                            font-size: 11px;
                            line-height: 14px;
                            margin-bottom: 6px;
                        }

                        @media screen and (min-width: $media1440) {
                            font-size: 13px;
                            line-height: 17px;
                            margin-bottom: 6px;
                        }
                    }

                    .value {
                        font-weight: 600;
                        font-family: 'Vela Sans Regular';

                        @media screen and (min-width: $media375) {
                            font-size: 16px;
                            line-height: 18px;
                        }

                        @media screen and (min-width: $media1024) {
                            font-size: 13px;
                            line-height: 18px;
                        }

                        @media screen and (min-width: $media1440) {
                            font-size: 14px;
                            line-height: 19px;
                        }
                    }
                }
            }
        }
    }
}
