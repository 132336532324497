@import 'src/variables';

nav #nav-menu {
    height: 100%;

    @media screen and (min-width: $media375) {
        display: none !important;
    }

    @media screen and (min-width: $media1024) {
        display: block !important;
    }

    svg {
        width: 12px;
        height: 12px;
    }
}

.NavMenu {
    height: 100%;

    @media screen and (min-width: $media1200) {
        margin-left: 35px;
    }

    li {
        display: flex;
        align-items: flex-start;
        margin-right: 5px;

        @media screen and (min-width: $media1024) {
            display: none;
        }

        @media screen and (min-width: $media1200) {
            display: flex;
        }

        a {
            display: flex;
            align-items: center;
            flex-direction: column;
            color: #000 !important;

            @media screen and (min-width: $media1200) {
                padding: 32px 10px 0 10px !important;
            }

            @media screen and (min-width: $media1920) {
                padding: 37px 15px 0 15px !important;
            }
        }

        @media screen and (min-width: $media1200) {
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
        }

        @media screen and (min-width: $media1920) {
            font-size: 14px;
            line-height: 19px;
        }

        &.selected {
            span {
                color: $primary-color;
            }

            a {
                padding-top: 13px !important;

                @media screen and (min-width: $media1920) {
                    padding-top: 18px !important;
                }

                svg {
                    margin-bottom: 7px;
                }
            }
        }
    }

    .dropdown {
        @media screen and (min-width: $media1024) {
            display: block;
        }

        @media screen and (min-width: $media1200) {
            display: none;
        }
    }

    #collapsed-nav-menu {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #f95a05;
        border-radius: 0px;
        padding: 0 30px;
        font-size: 12px;

        svg {
            margin-right: 5px;
            path,
            rect {
                fill: #f95a05;
                stroke: #f95a05;
            }

            &.uzd-icon {
                path:first-child {
                    fill: #f95a05;
                    stroke: transparent;
                }
                path:nth-child(2) {
                    fill: #ffffff;
                    stroke: #ffffff;
                    stroke-width: 0.1px;
                }
            }
        }
    }

    .dropdown-menu.show {
        width: 249px;
        height: 160px;
        background: $primary-panel-bg;
        border: 1px solid #f4efe1;
        border-radius: 5px;
        padding: 17px 20px;

        svg {
            margin-right: 10px;
        }

        span {
            font-size: 11px;
            line-height: 15px;
            color: #000000;
        }

        a {
            padding: 4px 12px;
        }
    }
}
