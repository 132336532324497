@import 'src/variables';

@font-face {
    font-family: 'Vela Sans Regular';
    src: url('./fonts/velasans-regular-webfont.woff2') format('woff2'),
    url('./fonts/velasans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Space Grotesk';
    src: url('./fonts/spacegrotesk-regular-webfont.woff2') format('woff2'),
    url('./fonts/spacegrotesk-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'space_groteskbold';
    src: url('./fonts/spacegrotesk-bold-webfont.woff2') format('woff2'),
    url('./fonts/spacegrotesk-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'space_groteskmedium';
    src: url('./fonts/spacegrotesk-medium-webfont.woff2') format('woff2'),
    url('./fonts/spacegrotesk-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'space_grotesksemibold';
    src: url('./fonts/spacegrotesk-semibold-webfont.woff2') format('woff2'),
    url('./fonts/spacegrotesk-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'space_grotesklight';
    src: url('./fonts/spacegrotesk-light-webfont.woff2') format('woff2'),
    url('./fonts/spacegrotesk-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html,
body,
#root {
    height: 100%;
    text-rendering: optimizeLegibility;
    // -webkit-font-smoothing: antialiased;
    font-family: 'Space Grotesk', sans-serif;
}

body {
    background-color: #fff !important;
}

#root {
    position: relative;
}

#root > .container {
    margin: 0 auto;
    padding: 0;
    overflow-y: auto;

    @media (min-width: $media375) {
    }

    @media (min-width: $media1024) {
        max-width: 1024px;
        margin-left: auto;
        margin-right: auto;
        height: 100% !important;
    }

    @media (min-width: 960px) and (max-width: 1023px) {
        max-width: 720px !important;
    }

    @media (min-width: 1025px) and (max-width: 1439px) {
        max-width: 1220px !important;
    }

    @media (min-width: 1400px) and (max-width: 1439px) {
        max-width: 1300px !important;
    }

    @media (min-width: $media1440) {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: $media1920) {
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: 992px) and (max-width: 1023px) {
    .navbar-expand-lg {
        justify-content: space-between !important;
    }
}

@media screen and (min-width: 992px) and (max-width: 1023px) {
    .navbar-expand-lg {
        justify-content: space-between !important;
    }
}

.dashboard-col {
    @media screen and (min-width: $media375) {
        .WalletStatus {
            display: none;
        }
    }

    @media screen and (min-width: $media1024) {
        .WalletStatus {
            display: none;
        }
    }

    .first-row {
        .InfoBlock:nth-child(0) {
            @media screen and (max-width: 1023) {
                width: 100%;
            }
        }
    }
}

.flex-even {
    flex: 1;
}

nav {
    .Disclaimer {
        @media (min-width: $media375) {
            display: none;
        }

        @media (min-width: $media1024) {
            display: flex;
        }
    }
}

.SidebarColumn {
    @media (min-width: $media375) {
        // display: none;

        &.active {
            top: 0;
            position: fixed;
            z-index: 2;
            display: block;

            .Sidebar {
                height: auto;

                &__footer {
                    display: flex;
                    margin-left: 25px;
                    flex-direction: row;
                    gap: 20px;
                    margin-top: 30px;
                }
            }
        }
    }

    @media (min-width: $media1024) {
        display: flex;
    }
}

footer {
    @media (min-width: $media375) {
        // display: none;
        height: 60px;
        padding-left: 10px;
    }

    @media (min-width: $media1024) {
        display: flex;
        align-items: center;
        padding-left: 40px;
        max-width: 1024px;
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: $media1440) {
        padding-left: 40px;
        max-width: 1440px;
    }

    @media (min-width: $media1920) {
        padding-left: 50px;
        max-width: 19200px;
    }

    ul {
        @media (min-width: $media1024) {
            margin-left: 20px;
        }

        @media (min-width: $media1440) {
            margin-left: 30px;
        }

        @media (min-width: $media1920) {
            margin-left: 47px;
        }

        margin-top: -5px;

        li {
            a {
                @media (min-width: $media1024) {
                    font-size: 8px;
                    line-height: 8px;
                    margin-left: 5px;
                }

                @media (min-width: $media1440) {
                    font-size: 10px;
                    line-height: 10px;
                    margin-left: 10px;
                }

                @media (min-width: $media1920) {
                    font-size: 10px;
                    line-height: 10px;
                    margin-left: 20px;
                }

                text-decoration: none;
                color: #d3d3d3;
            }
        }
    }

    .mobile {
        @media (min-width: $media375) {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            margin-left: 20px;
            margin-bottom: 20px;
            margin-top: 20px;
        }

        @media (min-width: $media1024) {
            display: none;
        }

        a {
            display: block;
            text-decoration: none;
            font-size: 12px;
            line-height: 16px;
            color: #a2a2a2;
        }
    }

    .list-inline {
        @media (min-width: $media375) {
            display: none;
        }

        @media (min-width: $media1024) {
            display: block;
        }
    }
}

.copyright {
    font-style: normal;
    font-weight: normal;
    color: #dedede;
    display: block;

    @media (min-width: $media375) {
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0.5px;
        margin-left: 20px;
    }

    @media (min-width: $media1024) {
        font-size: 8px;
        line-height: 8px;
        letter-spacing: 0.5px;
        margin-left: 0;
    }

    @media (min-width: $media1440) {
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0.5px;
    }
}

.toast-container.toasts {
    z-index: 1111;
    position: fixed !important;
}

.zun-link {
    color: #f95404;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: #f95404;
    }
}

.toast {
    border-radius: 5px;
}

.hide {
    display: none !important;
}

.panel {
    background: #f8f8f8;
    border-radius: 20px;

    .panel-body {
        padding: 21px 26px;
        font-size: 14px;
    }
}

.Slippage {
    @media (min-width: $media375) {
        margin-top: 10px;
        width: 100%;
    }

    @media (min-width: $media1024) {
        margin-top: 20px;
        margin-left: 0;
    }

    @media (min-width: $media1440) {
        margin-top: 0;
        width: 115px;
        margin-left: 20px;
    }

    .panel-body {
        font-size: 11px;
    }
}

.zun-button {
    background: linear-gradient(0deg, #f96501 0%, #ffa001 100%);
    border-radius: 44px;
    text-align: center;
    color: #ffffff;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-family: 'Space Grotesk';
    text-decoration: none;

    @media (min-width: $media375) {
        font-size: 14px;
        line-height: 18px;
        padding: 10px 28px;
    }

    @media (min-width: $media1024) {
        font-size: 11px;
        line-height: 14px;
        padding: 8px 28px;
    }

    @media (min-width: $media1440) {
        font-weight: 500;
        font-size: 13px;
        line-height: 17px;
        padding: 10px 30px;
    }

    @media (min-width: $media1920) {
        font-size: 16px;
        line-height: 22px;
    }

    &:hover {
        text-decoration: none;
        color: #fff;
    }
}

button:disabled,
button.disabled,
.disabled {
    opacity: 0.5;
    pointer-events: none;
}

input[type='checkbox'] {
    appearance: none;
    background: #e7e7e7;
    border: 1px solid #f4efe1;
    border-radius: 5px;
    position: relative;

    &:checked {
        &:after {
            content: '';
            width: 7px;
            height: 7px;
            position: absolute;
            background: #000000;
            border-radius: 5px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.tooltip-arrow::before {
    border-right-color: #f8f8f8 !important;
}

.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #f8f8f8 !important;
}

.tooltip-inner {
    background: #f8f8f8 !important;
    border-radius: 5px !important;
    color: #898989 !important;
    padding: 10px 18px !important;
    font-size: 11px;
    line-height: 14px;
}

.overflow {
    overflow: hidden;
}

.block-bg {
    background: linear-gradient(0.02deg, #fff9f0 60.75%, #fff8ed 87.27%);
}

.text-primary {
    --bs-primary-rgb: 246, 74, 0;
}

.text-secondary {
    --bs-secondary-rgb: 99, 99, 99;
}

.text-small {
    font-size: 10px;
}

.Sidebar {
    .InfoBlock:first-child {
        .InfoBlock__title {
            margin-left: 5px;
        }
    }
}

.chameleon-svg {
    fill: #000;
}

.chameleon-svg-inverse {
    fill: #fff;
}

.chameleon-text {
    color: #000;
}

.chameleon-text-gray {
    color: rgba(0, 0, 0, .4);
}

.soon-placeholder-block {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .7);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px rgba(0, 0, 0, .05);
    border-radius: 20px;
}

.toast {
    a {
        text-decoration-color: #000000;
        text-decoration-thickness: .08em;
        text-underline-offset: 3px;
        text-decoration-style: solid;
    }

    .close-btn {
        background-color: transparent;
        border: none;
        outline: none;
        position: absolute;
        right: 5px;
        top: 5px;
    }

    .trans-link,
    .close-icon {
        path {
            stroke: #000;
        }
    }
}

.text-chameleon {
    color: #000;
}

.border-chameleon {
    border-color: #D0D0D0;
}

body.dark {
    background-color: #000 !important;

    .soon-placeholder-block {
        border: solid 1px rgba(255, 255, 255, .2);
        background-color: rgba(0, 0, 0, .7);
        color: rgba(255, 255, 255, .7);
    }

    .toast {
        background-color: #090909 !important;
        color: #fff;

        a {
            text-decoration-color: #ffffff;
            text-decoration-thickness: .08em;
            text-underline-offset: 3px;
            text-decoration-style: solid;
        }

        .trans-link,
        .close-icon {
            path {
                stroke: #fff;
            }
        }
    }

    .chameleon-svg {
        fill: #fff;
    }

    .chameleon-svg-inverse {
        fill: #000;
    }

    .chameleon-text {
        color: #fff;
    }

    .chameleon-text-gray {
        color: rgba(255, 255, 255, .4);
    }

    .text-chameleon {
        color: #fff;
    }

    .border-chameleon {
        border-color: rgba(255, 255, 255, .2);
    }

    .coin-item {
        &:hover {
            background-color: #474747 !important;
        }
    }

    .WalletButton {
        &::after {
            background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #1d1d1d 80%);
        }
    }

    input,
    .form-control:focus {
        color: #fff;
    }

    .Preloader {
        &.blocker {
            background-color: rgba(0, 0, 0, 0.3);
        }
    }

    .modal-content {
        border: 1px solid #484848;
    }

    .ApyDetailsModal {
        .modal-content {
            border: 1px solid #484848;
            background: #1b1a1a;
        }

        .accordion-button {
            background: #434343;
            color: #fff;
        }

        .accordion-item {
            color: #fff;

            // &:has(div.collapse.show) {
            //     background-color: #2e2e2e;
            // }
        }

        .accordion-button:not(.collapsed)::after {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05IDE4QzEzLjk3MDYgMTggMTggMTMuOTcwNiAxOCA5QzE4IDQuMDI5NDQgMTMuOTcwNiAwIDkgMEM0LjAyOTQ0IDAgMCA0LjAyOTQ0IDAgOUMwIDEzLjk3MDYgNC4wMjk0NCAxOCA5IDE4Wk00LjE5MTg2IDguMTM5NTZWOS44NjU1OUwxMy44MDgzIDkuODY1NTlWOC4xMzk1Nkg0LjE5MTg2WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+IA==');
        }

        .accordion-button::after {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05IDE4QzEzLjk3MDYgMTggMTggMTMuOTcwNiAxOCA5QzE4IDQuMDI5NDQgMTMuOTcwNiAwIDkgMEM0LjAyOTQ0IDAgMCA0LjAyOTQ0IDAgOUMwIDEzLjk3MDYgNC4wMjk0NCAxOCA5IDE4Wk04LjYzMDM3IDEzLjgwODNINi45MDQzNUw4LjAxNjQgOS44NjU1N0g0LjE5MTc1VjguMTM5NTRIOC41MDMyM0w5LjYxNjY4IDQuMTkxODZIMTEuMzQyN0wxMC4yMjkzIDguMTM5NTRIMTMuODA4MlY5Ljg2NTU3SDkuNzQyNDNMOC42MzAzNyAxMy44MDgzWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+IA==');
        }
    }

    .TransactionHistory,
    .Slippage {
        background-color: #2a2a2a;
        border-color: #333333;
        color: #fff;
    }

    .RebaseHistory,
    .PieChart__StratList__Item .strat-desc {
        color: #c8c8c8;
    }

    // .buttons {
    //     > div:not(.Preloader),
    //     > a {
    //         background: #555555 !important;
    //     }
    // }

    .contract-buttons {
        svg {
            path {
                fill: #fff;
            }
        }
    }

    .panel {
        background-color: #494949;

        a[href^='http'] {
            color: red !important;
        }
    }

    .RebaseHistory {
        .header {
            div {
                background: #333333;
            }
        }
    }

    .LockHistory {
        .header {
            div {
                color: #c8c8c8;
            }
        }
    }

    .zun-staking-logo {
        .zun-logo,
        .zun-text {
            path {
                fill: #fff;
            }
        }

        .staking-text {
            rect {
                fill: #525252;
            }

            path {
                fill: #fff;
            }
        }
    }

    .EthMergeWarningModal {
        span,
        h3,
        p {
            color: #fff;
        }

        .disabled-section {
            border-color: #3c3c3c;
        }
    }

    .WalletButton {
        svg {
            rect:first-child {
                fill: #424242;
            }

            rect:last-child {
                fill: #646464;
            }
        }

        .plus {
            rect,
            path {
                fill: none;
                stroke: #fff;
            }

            path:last-child {
                fill: #fff;
            }
        }
    }

    .pending-icon,
    .zun-icon {
        path {
            fill: #fff;
        }
    }

    .text {
        color: #fff;
    }

    .FastDepositForm__MobileToggle__Icon {
        background: linear-gradient(225.25deg, #171717 16.17%, #161616 85.98%);
    }

    .badge.bg-secondary {
        background: #1d1d1d !important;
        text-decoration: none;
        color: #fff !important;
    }

    .btn-secondary {
        background: linear-gradient(140.24deg, #212121 44.18%, #1d1d1d 144.27%);

        &.disabled {
            background-color: #FFA54E;
        }
    }

    .StakingSummary,
    .UzdStakingSummary {
        background: linear-gradient(77.82deg, #202020 -4.26%, #232323 88.51%);
        color: #fff;

        .staking-icon {
            .zun {
                fill: #fff;
            }
        }

        .staking-text {
            path {
                fill: #fff;
            }
        }

        .block {
            background: #353535;

            .title {
                color: #c8c8c8;
            }

            .value {
                color: #fff;
            }
        }

        .staking-icon.zun {
            path:nth-child(2),
            path:nth-child(3),
            path:nth-child(4) {
                fill: #fff;
            }
        }

        .staking-icon.zeth {
            path:nth-child(2),
            path:nth-child(4),
            path:nth-child(1) {
                fill: #fff;
            }
        }
    }

    .StakingSummary {
        &__Selected {
            border-color: #fe9002;
            background: linear-gradient(77.82deg, #FF6A00 -4.26%, #FF8800 88.51%);

            .btn-secondary {
                pointer-events: none;
                color: #FFFFFF;
                background-color: #FFA54E;

                &.disabled {
                    opacity: 1;
                    color: #FFFFFF;
                    background-color: #FFA54E;
                }
            }

            .values {
                .block {
                    background: #FFA54E;

                    .title {
                        color: #fff;
                    }

                    .value {
                        color: #fff;
                    }
                }
            }
        }
    }

    .WalletStatus__Icon,
    .WalletStatus__Connect,
    .WalletStatus_anal__inner__wallet {
        background: #333333 !important;
    }

    .pool-item,
    .gray-block {
        background: #333333 !important;

        .name,
        .subtitle,
        .value {
            color: #fff !important;
        }

        .name {
            color: #c8c8c8 !important;
        }
    }

    .supporters-links a {
        background: #161616;
    }

    .AnalyticsContainer {
        h2,
        .name,
        .subtitle,
        .value,
        .gray-block,
        .PieChart2 .PieChart__StratList .PieChart__StratList__Item a,
        .panel,
        .links a {
            color: #fff !important;
        }

        .tbl {
            color: #fff;
            border-color: #383838;

            > div {
                border-color: #383838;
            }
        }

        .panel {
            background-color: #161616;
        }

        .uzd-logos {
            svg:nth-child(2) {
                path {
                    fill: #fff;
                }
            }
        }

        .protocol-logos {
            svg:nth-child(1) {
                path {
                    fill: #fff;
                }
            }
        }

        .risk-section {
            .nav-link {
                &.active {
                    background: #161616;
                }

                background: #161616;
                border-color: transparent;
                color: #fff;
            }
        }
    }

    #MobileSidebar {
        background-color: #2a2a2a;

        .inner {
            color: #fff;
        }

        .secondary-menu {
            li {
                a {
                    color: #a1a1a1;
                }
            }
        }
    }

    .NetworkSelector {
        background: #252525;

        span {
            color: #fff;
        }

        &__Toggler {
            path {
                stroke: #fff;
            }
        }
    }

    .UnsupportedChain__Content {
        background: #292929;
        color: #fff;

        .NetworkSelector {
            background-color: #313131;
        }
    }

    .WalletStatus__Icon,
    .WalletStatus__Connect {
        background-color: #2a2a2a !important;
    }

    .WalletStatus__inner__network {
        .network-eth {
            path:nth-child(1) {
                fill: #b4b4b4;
            }

            path:nth-child(2) {
                fill: #e4e4e4;
            }

            path:nth-child(3) {
                fill: #a4a4a4;
            }

            path:nth-child(4) {
                fill: #bdbdbd;
            }

            path:nth-child(5) {
                fill: #a4a4a4;
            }

            path:nth-child(6) {
                fill: #bdbdbd;
            }
        }
    }

    .WalletStatus__Icon,
    .WalletStatus__Connect {
        background-color: #2a2a2a !important;
    }

    .WalletStatus__inner__network {
        .network-eth {
            path:nth-child(1) {
                fill: #b4b4b4;
            }

            path:nth-child(2) {
                fill: #e4e4e4;
            }

            path:nth-child(3) {
                fill: #a4a4a4;
            }

            path:nth-child(4) {
                fill: #bdbdbd;
            }

            path:nth-child(5) {
                fill: #a4a4a4;
            }

            path:nth-child(6) {
                fill: #bdbdbd;
            }
        }
    }

    .WalletStatus {
        svg {
            path:nth-child(1) {
                fill: #494949;
                stroke: transparent;
            }

            path:nth-child(3) {
                fill: #646464;
                stroke: transparent;
            }

            path:nth-child(3) {
                // fill: red;
                stroke: transparent;
            }

            mask {
                path {
                    display: none;
                }
            }
        }
    }

    .copyright {
        color: #424242;
    }

    footer {
        ul {
            li {
                a {
                    color: #a1a1a1;
                }
            }
        }
    }

    input[type='text'] {
        border-color: $dark-border-color;
        background-color: $dark-panel-bg;
        color: $primary-color !important;
    }

    .BscMigrationModal,
    .modal-content,
    .modal-header,
    .Sidebar__Header > span {
        background: linear-gradient(180deg, #1b1b1b 0%, #1d1d1d 100%) !important;
    }

    .BscMigrationModal h3,
    .BscMigrationModal p,
    .modal-header .btn-close {
        color: #fff;
    }

    .modal-header .btn-close {
        filter: invert(1);
    }

    .navbar {
        background: #000;

        .NavMenu li.selected {
            background: linear-gradient(
                    180deg,
                    rgba(250, 91, 6, 0) 0%,
                    rgba(250, 91, 6, 0.04) 100%
            );

            span {
                color: #f95a05;
            }
        }

        .divider {
            path {
                stroke: #232323;
            }
        }
    }

    .FastDepositForm {
        background: linear-gradient(81.61deg, #1a1a1a 6.26%, #1f1f1f 93.21%);
        border: none;

        &__Description {
            color: #6a6a6a;
        }
    }

    .UzdContainer__Actions_Inner {
        // background: linear-gradient(9.38deg, #222222 3.94%, #111111 89.76%);
        border: none;
        color: #fff;

        .s-coin {
            background: #171717;
            border: 1px solid #232323;

            input[type='text'] {
                background-color: transparent;
                border-color: #232323;
                color: #fff;
            }

            .coin {
                color: #fff;
            }
        }

        .protocol_fee__text {
            color: #fff;
        }

        .curve-boost {
            // background: linear-gradient(88.25deg, #262626 6.57%, #252525 94.5%);

            div {
                color: #fff;
            }
        }

        .counter {
            background-color: #3f3f3f;
            color: #fff;
        }

        .go-to-curve {
            background: #434343;

            span {
                color: #fff;
            }
        }

        .how-it-works,
        ul li span {
            color: #fff !important;
        }
    }

    .SidebarColumn {
        @media (min-width: $media375) {
            background: #1e1e1e;
        }

        @media (min-width: $media1024) {
            background: #2a2a2a;
        }

        .InfoBlock:first-child {
            background: none;
        }

        .Sidebar__Title,
        .Sidebar__Description {
            color: #ffffff;
        }

        &::after {
            background: linear-gradient(0.02deg, #181818 76.02%, #0b0b0b 95.61%);

            @media (min-width: $media375) {
                display: none;
            }

            @media (min-width: $media1024) {
                display: block;
            }
        }
    }

    input[type='checkbox'] {
        background: #313131;
        border: none;

        &:checked {
            &::after {
                background: #fff;
            }
        }
    }

    .DirectAction {
        span {
            color: #828282;
        }

        svg {
            path {
                fill: #fff;
            }
        }
    }

    .LinkBlock__title {
        color: rgba(255, 255, 255, 0.53);
    }

    .LinkBlock__active .LinkBlock__title {
        color: rgba(249, 84, 4, 0.53);
    }

    input[type='text'],
    .Input .coin {
        background: #1d1d1d !important;
    }

    .Disclaimer__Content a {
        color: #fff;
        border-bottom: dashed 1px rgba(255, 255, 255, 0.5);
    }

    .FastDepositInput {
        background: none;
        border-color: #232323;

        .divider {
            background-color: #232323;
        }

        .coinName {
            color: #fff;
        }

        input {
            background-color: transparent;
        }
    }

    .second-row {
        // background: linear-gradient(9.38deg, #1c1c1c 3.94%, #1a1919 89.76%);

        .InfoBlock {
            background: none;
        }
    }

    .popover {
        border-radius: $border-radius;
        overflow: hidden;
    }

    .popover-body {
        background-color: #3e3e3e;
        color: #fff;
    }

    .popover-arrow::after {
        border-right-color: #3e3e3e !important;
        background-color: #3e3e3e;
        color: #fff;
        display: none;
    }

    .popover-arrow::after {
        border-right-color: #3e3e3e !important;
    }

    .popover {
        box-shadow: 0 0 5px #575757;

        .balance {
            background: #1d1d1d;
        }

        .popover-body {
            background: linear-gradient(86.3deg, #252525 4.51%, #222222 98.06%);
        }
    }

    .TransactionHistory,
    .RebaseHistory {
        .empty {
            svg {
                rect {
                    fill: #373737;
                }
            }
        }
    }

    span,
    .ApyChart__Title,
    .PieChart__Header,
    .InfoBlock__description,
    .ApyChart__Selector span,
    .PieChart__StratList__Item__Name,
    .WalletStatus__connected .state,
    .PieChart .PieChart__StratList .PieChart__StratList__Item a,
    .DepositContent__Title,
    .Input .coinName,
    .Input input,
    .Lockdrop__Title,
    .DepositStory__Title,
    .DepositStory__Table thead tr td,
    .PendingBalance__val,
    .TransactionHistory__Title,
    .TransactionHistory__List th,
    .RebaseHistory__List th,
    .Sidebar__Content__Data,
    .total-income,
    .ApyBar__title,
    .InfoBar .values .block .name,
    .InfoBar .values .block .value,
    .card-body > .title,
    .Deposit__RightBlock .title,
    .UzdContainer__Actions .InfoBars .InfoBar .title,
    .UzdContainer__Actions .InfoBars .InfoBar .values .block span.name,
    .UzdContainer__Actions .InfoBars .InfoBar .values .block span.value,
    .Uzd-Zlp-Balances .title,
    .ApyBar__counters .ApyBar__Counter__Value,
    #networks-selector,
    #networks-selector .network-item .choose-btn,
    .badge-light,
    #MobileSidebar .inner .listings a,
    .FastDepositForm__MobileToggle__Title,
    .modal-title,
    .title,
    .WalletStatus__Connect,
    .mobile-menu-title,
    .CollateralChart .PieChart__StratList .PieChart__StratList__Item a,
    .CollateralChart__more,
    .StrategiesList {
        color: #fff;
    }

    .card.InfoBar {
        background: linear-gradient(86.2deg, #141414 4.31%, #171717 40.59%) !important;

        .block {
            background: #1d1d1d !important;
        }
    }

    .coins {
        .coin {
            background: #1d1d1d !important;
        }
    }

    .InfoBars {
        .InfoBar:first-child {
            background: linear-gradient(80.09deg, #141414 10.29%, #171717 96.88%) !important;

            .block {
                background: #1d1d1d !important;
            }
        }
    }

    .letter {
        fill: #fff;
    }

    .mint-redeem-inputs {
        .swap {
            background: #1c1c1c;
        }
    }

    .StrategiesList {
        background: linear-gradient(63.79deg, #131313 11.66%, #161616 89.17%);

        .desc {
            color: #c8c8c8;
        }

        .block {
            background: #2e2d2d;

            .title {
                color: #989898;
            }
        }
    }

    .PieChart__Tooltip,
    .StrategiesList__List-Item {
        background-color: #1d1d1d;
        color: #fff;
    }

    .mobile-menu .selected span {
        color: #fff !important;
    }

    #networks-selector .close img {
        filter: invert(1);
    }

    .all-services-btn {
        color: #fff;
        border-color: #fff;
    }

    .all-services-panel {
        background-color: #000;
        color: #fff;

        .law-links {
            a {
                background-color: #1d1d1d;
                color: #fff;
            }
        }

        .media-links {
            a {
                background: linear-gradient(53.71deg, #e3e5fa 15.83%, #ffffff 65.73%);
            }
        }

        .about {
            color: #828282;
        }

        .official-links {
            a {
                text-decoration-color: #c2c2c2;

                span {
                    color: #c2c2c2;
                }
            }
        }
    }

    .audits {
        a:nth-child(1) {
            svg {
                path:nth-child(3) {
                    fill: #fff;
                }
            }
        }

        a:nth-child(2) {
            svg {
                path:nth-child(1) {
                    fill: #fff;
                }
            }
        }
    }

    .SidebarColumn .Sidebar__Content__Data .profits .title,
    .ApyBar__counters .ApyBar__Counter__Title span {
        color: #c8c8c8;
    }

    .text-muted {
        color: rgba(255, 255, 255, 0.6) !important;
    }

    .total-income .title,
    .total-income .value {
        color: #fff !important;
    }

    #trans-story-mobile-btn {
        span {
            color: #f64a00 !important;
        }
    }

    .hint {
        path {
            fill: #fff;
        }
    }

    .InfoBlock__description_color {
        color: $primary-color;
    }

    .ActionSelector__Action {
        border-bottom-color: transparent;

        &__Active {
            border-bottom-color: #fb7501;
        }
    }

    .strats-chart-col,
    .hist-apy-col,
    .InfoBlock,
    .operation-col {
        background: #1e1e1e;
    }

    .Sidebar__Header > svg path,
    #MobileSidebar > svg path {
        fill: #fff;
    }

    #MobileSidebar .close,
    #all-services .close {
        filter: invert(1);
    }

    .nav-menu svg path {
        stroke: #fff;
    }

    #collapsed-nav-menu {
        // background: linear-gradient(180deg, rgba(250, 91, 6, 0) 0%, rgba(250, 91, 6, 0.04) 100%);
        color: $primary-color;

        span {
            color: $primary-color;
        }
    }

    .SidebarColumn {
        @media (min-width: $media375) {
            background: none;
        }

        @media (min-width: $media1024) {
            background: linear-gradient(
                    0.02deg,
                    rgba(38, 38, 38, 0.45) 76.02%,
                    rgba(47, 47, 47, 0.45) 95.61%
            );
        }
    }

    #nav-menu {
        .dropdown-menu.show {
            background: #272727;
            border: 1px solid #333333;

            a {
                span {
                    color: #fff;
                }

                &:hover {
                    background-color: transparent;
                }
            }
        }
    }

    .Disclaimer {
        background: linear-gradient(262.84deg, #1e1e1e -27.39%, #2c2c2c 71.59%);
        color: #fff;
    }

    .InfoBlock_colorful {
        background: linear-gradient(9.38deg, #1c1c1c 3.94%, #1a1919 89.76%);
    }

    .UzdContainer__Actions_Inner .swap svg {
        border-color: #1b1b1b;
        background-color: transparent;
    }

    .WalletStatus__inner {
        background: linear-gradient(81.95deg, #141414 5.86%, #151515 93.43%);
    }

    .Sidebar__Content__Data,
    .withdraw,
    .deposit,
    .PieChart,
    .card,
    .Deposit__RightBlock,
    .TransactionHistory,
    .ThemeSwitcher,
    .Input .max,
    label.coin,
    .Counter,
    .mint-redeem-inputs,
    .Uzd-Zlp-Balances,
    #networks-selector .network-item,
    .badge-light {
        background: linear-gradient(132deg, #272727 10.02%, #181818 93.49%) !important;
    }

    .Form {
        background: linear-gradient(64.61deg, #141414 9.76%, #171717 99.42%);
    }

    .ApyBar {
        background: linear-gradient(71.21deg, #1a1a1a 12.22%, #1f1f1f 86.71%);

        .soon {
            background-color: rgba(0, 0, 0, 0.7);

            span {
                color: #fff;
            }
        }
    }

    .soon {
        color: #fff;
    }

    .card.mint-card {
        background: linear-gradient(57.3deg, #141414 -9.62%, #171717 99.29%) !important;
    }

    #networks-selector {
        background-color: #000;
    }

    .WalletStatus__toggler svg path {
        fill: #fff !important;
    }

    .FastDepositForm__Title svg path {
        fill: #494949;
    }

    .card {
        .card-body {
            svg.bg path {
                // fill: #444;
            }
        }

        &.buy-uzd {
            .card-body {
                svg.bg {
                    path {
                        fill: linear-gradient(
                                142.72deg,
                                rgba(255, 255, 255, 0.35) 8.79%,
                                rgba(255, 255, 255, 0) 52.14%
                        ) !important;
                    }
                }

                .gray-block {
                    background: #fafafa !important;

                    .name,
                    .value {
                        color: #000 !important;
                    }
                }
            }
        }
    }

    .divider {
        background-color: #303030 !important;
    }

    .CoinSelector,
    #networks-selector .network-item .choose-btn,
    .ApyBar__Counter,
    .Counter__Title__Buttons > div {
        background: #333333;
    }

    .Coin-Selector__Items.big {
        border: 1px solid #353535;
    }

    .CoinSelector .selector path {
        stroke: #7d7d7d;
    }

    .Counter__Title__Buttons > div {
        background: #262626;
    }

    input[type='radio'] {
        & + .fake-radio {
            background: #515151 !important;
        }

        &:checked {
            & + .fake-radio {
                &::after {
                    background-color: #fff !important;
                }
            }
        }
    }

    .btn.btn-light {
        background: #1d1d1d !important;
        color: #fff;
        border-color: transparent;
    }

    .TransactionHistory__List-Item {
        background: linear-gradient(132deg, #181818 10.02%, #272727 93.49%) !important;
    }

    button {
        svg {
            path,
            rect,
            circle {
                fill: #fff;
            }
        }
    }

    .link-icon {
        path {
            fill: #fff;
        }
    }

    .UzdContainer .ZLPBalance__Logo {
        path {
            fill: #fff;
        }
    }

    .WelcomeCarousel {
        background: linear-gradient(0.02deg, #1e1e1e 76.02%, #282828 95.61%);

        h1 {
            color: #ffffff;
        }

        &__Content__Logo {
            path {
                fill: #fff;
            }
        }

        .carousel-item {
            background: transparent;

            h1,
            span {
                color: #ffffff;
            }
        }

        .carousel-control-prev,
        .carousel-control-next {
            background: #646464;
        }
    }

    .TransactionHisoryMobile {
        background: #202020;

        &__Title {
            color: #ffffff;
        }
    }

    .Form {
        .divider {
            background-color: $dark-border-color;
        }

        .inner {
            // background: #202020;
        }

        .Input {
            // background-color: #2b2b2b;
            border-color: $dark-border-color;

            input {
                background-color: #272727;
                border-color: $dark-border-color;
            }
        }

        .withdraw-all {
            background: $dark-panel-bg;
            color: #878787;
        }

        .direct-deposit span,
        .direct-dithdraw span {
            color: #828282;
        }
    }

    .Lockdrop,
    .Staking {
        &__Locked,
        &__Unclaimed {
            background: $dark-panel-bg;

            &__Title {
                color: #fff;
            }
        }

        &__Title {
            color: #fff;
        }

        &__Unclaimed {
            &__ICON {
                path {
                    fill: #494949;
                }
            }

            &__USD {
                color: $dark-muted-text-color;
            }
        }

        &__Locked {
            &__Token {
                background: $dark-panel-bg;
                border-color: $dark-border-color;

                .divider {
                    background-color: $dark-border-color;
                }

                .token {
                    color: #fff;
                }
            }
        }

        .DepositStory__Table thead tr td {
            border-color: $dark-border-color;
        }

        .DepositStory__Table tbody tr td {
            color: $dark-muted-text-color;
        }

        .DepositStory__Table tbody tr td button {
            background: #2a2a2a;
            color: #878787;

            &.disabled {
                background: #252323;
            }
        }
    }
}

.badge {
    border-radius: $border-radius !important;
    padding: 7px 20px !important;
    font-size: 14px !important;
    line-height: 19px !important;

    &-light {
        background: #f0f0f0;
        color: #000 !important;
    }
}

.text-primary {
    color: $primary-color;
}

.btn {
    border-radius: $border-radius !important;
    border-color: transparent;
}

.btn-sm {
    padding: 6px 15px !important;
    font-size: 14px !important;
    line-height: 19px !important;
    max-height: 31px;

    span {
        font-size: 14px !important;
    }
}

.btn-light {
    background-color: $btn-secondary-color !important;
    border-color: transparent !important;

    &:hover {
        background-color: #d4d4d4 !important;
    }
}

.dashboard {
    display: flex;
    flex-wrap: wrap;
}

body.overflow {
    .carousel {
        pointer-events: none;
        opacity: 0;
        z-index: 0;
    }
}

.carousel-indicators button {
    width: 7px !important;
    height: 7px !important;
    border-radius: 100% !important;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.5 13C10.0898 13 13 10.0899 13 6.5C13 2.91015 10.0899 2.54413e-07 6.5 5.68248e-07C2.91015 -7.15916e-08 7.15914e-08 2.91015 -5.68248e-07 6.5C-2.54413e-07 10.0899 2.91015 13 6.5 13ZM7.83595 4.79911C8.09325 4.57397 8.11932 4.18288 7.89419 3.92558C7.66905 3.66828 7.27796 3.64221 7.02066 3.86735L4.54447 6.03401C4.41013 6.15156 4.33307 6.32138 4.33307 6.49989C4.33307 6.67841 4.41013 6.84823 4.54447 6.96578L7.02066 9.13244C7.27796 9.35758 7.66905 9.33151 7.89419 9.07421C8.11932 8.81691 8.09325 8.42582 7.83595 8.20068L5.8922 6.49989L7.83595 4.79911Z' fill='white' fill-opacity='1'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.5 1.1365e-06C2.91015 5.08827e-07 -5.08827e-07 2.91015 -1.1365e-06 6.5C-1.76417e-06 10.0899 2.91015 13 6.5 13C10.0898 13 13 10.0899 13 6.5C13 2.91015 10.0899 1.76417e-06 6.5 1.1365e-06ZM5.16404 8.20089C4.90675 8.42603 4.88067 8.81712 5.10581 9.07442C5.33095 9.33172 5.72204 9.35779 5.97934 9.13265L8.45553 6.96599C8.58987 6.84844 8.66693 6.67862 8.66693 6.50011C8.66693 6.3216 8.58987 6.15178 8.45553 6.03423L5.97934 3.86756C5.72204 3.64242 5.33095 3.6685 5.10581 3.92579C4.88067 4.18309 4.90675 4.57418 5.16405 4.79932L7.1078 6.50011L5.16404 8.20089Z' fill='white' fill-opacity='1'/%3E%3C/svg%3E%0A") !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    opacity: 0.71;
    width: 15px !important;
    height: 15px !important;

    &:hover {
        opacity: 1;
    }
}

.features-slider {
    background-color: #cecece;
    border-radius: $border-radius;

    @media (min-width: $media375) {
        // width: 100%;
        margin-bottom: 20px;
    }

    @media (min-width: $media1024) {
        // width: 275px;
        margin-bottom: 0;
    }

    @media (min-width: $media1440) {
        // width: 353px;
    }

    @media (min-width: $media1920) {
        // width: 610px;
    }

    .carousel-inner,
    .carousel-item {
        height: 100%;
    }

    .carousel-item {
        border-radius: $border-radius;
        position: relative;

        @media (min-width: $media375) {
            // width: 100%;
            height: 100%;
        }

        @media (min-width: $media1024) {
            // width: 100%;
            height: 100%;
        }

        @media (min-width: $media1440) {
            // width: 353px;
            height: 100%;
        }

        @media (min-width: $media1920) {
            // width: 609px;
            height: 100%;
        }

        &.zun {
            background: linear-gradient(45deg, #0607ff 7.33%, #0203ba 92.02%);
        }

        &.usd {
            background: linear-gradient(66.52deg, #ff3902 -39.41%, #ffa701 100%);
        }

        &.eth {
            background: linear-gradient(66.52deg, #212121 39.41%, #626262 100%);
        }

        &.audits {
            background: linear-gradient(0deg, #0808ff 13.19%, #00f 84.34%);
        }

        &.supporters {
            background: linear-gradient(45deg, #1a0143 13.19%, #1f015f 84.34%);
        }

        &.zun,
        &.usd,
        &.eth,
        &.audits,
        &.supporters {
            svg {
                position: absolute;
                left: 50%;
                top: 10%;
                transform: translate(-50%, 0);
                height: 60%;
                width: auto;
                aspect-ratio: 16/9;
            }
        }
    }

    &.slim {
        .title {
            display: none;
        }

        .yeld {
        }

        .curve {
            .logo {
                width: 236px;
                height: 82px;
                margin-top: 0;
            }
        }

        .carousel-indicators {
            margin-bottom: 0.2rem;
        }
    }
}

.ApyBarWrapper {
    @media (min-width: $media375) {
        width: 100%;
    }

    @media (min-width: $media1024) {
        width: auto;
    }
}

.ApyBar {
    background: linear-gradient(78.51deg, #f7f7f7 12.17%, #f6f6f6 65.32%);
    border-radius: $border-radius;
    position: relative;

    @media (min-width: $media375) {
        padding: 20px;
        margin: 0;
    }

    @media (min-width: $media1024) {
        // width: 300px;
        // height: 316px;
        padding: 20px;
        // margin: 21px 20px 0 25px;
    }

    @media (min-width: $media1440) {
        // width: 517px;
        // height: 316px;
        padding: 25px 25px 0 25px;
        // margin: 21px 23px 0 48px;
    }

    @media (min-width: $media1920) {
        // width: 610px;
        // height: 373px;
        padding: 25px;
        // margin: 21px 23px 0 48px;
    }

    .soon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: $border-radius;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    &__title {
        font-family: 'Space Grotesk';
        font-weight: 500;
        font-size: 13px;
        line-height: 17px;
        margin-bottom: 20px;

        @media (min-width: $media375) {
            font-size: 14px;
            line-height: 18px;
        }

        @media (min-width: $media1920) {
            font-size: 14px;
            line-height: 18px;
        }
    }

    &__counters {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (min-width: $media375) {
            gap: 20px;
        }

        @media (min-width: $media1024) {
            gap: 0;
        }

        .ApyBar__Counter {
            background: #e4e4e4;
            border-radius: $border-radius;

            @media (min-width: $media375) {
                width: calc(50% - 10px);
                height: 63px;
                margin-bottom: 0;
            }

            @media (min-width: $media1024) {
                width: 115px;
                height: 53px;
                margin-bottom: 12px;

                &:nth-child(1) {
                    width: 160px;
                }
            }

            @media (min-width: $media1200) {
                width: 130px;
                height: 64px;
                margin-bottom: 0;

                .chameleon-text-gray {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: calc(100% - 70px);
                }

                &:nth-child(1) {
                    width: 150px;
                }

                &:nth-child(2) {
                    width: 140px;
                }

                &:nth-child(3) {
                    width: 100px;
                }
            }

            @media (min-width: $media1440) {
                width: 130px;
                height: 64px;
                margin-bottom: 0;
                width: auto;

                .chameleon-text-gray {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: calc(100% - 20px);
                }

                &:nth-child(1) {
                    width: 168px;
                }

                &:nth-child(2) {
                    width: 170px;
                }

                &:nth-child(3) {
                    width: 130px;
                }
            }

            @media (min-width: $media1920) {
                width: calc(33% - 5px);
                height: 75px;
                margin-bottom: 0;
            }

            &__Title {
                @media (min-width: $media375) {
                    margin-top: 15px;
                    margin-bottom: 2px;
                }

                @media (min-width: $media1024) {
                    margin-top: 11px;
                }

                @media (min-width: $media1440) {
                    margin-top: 11px;
                }

                @media (min-width: $media1920) {
                    margin-top: 15px;
                }

                span {
                    display: block;
                    font-family: 'Space Grotesk';
                    font-weight: 500;

                    @media (min-width: $media375) {
                        font-size: 14px;
                        line-height: 14px;
                        margin-left: 15px;
                    }

                    @media (min-width: $media1024) {
                        font-size: 11px;
                        line-height: 14px;
                    }

                    @media (min-width: $media1440) {
                        font-size: 13px;
                        line-height: 17px;
                        margin-left: 19px;
                    }

                    @media (min-width: $media1920) {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }

                .hint {
                    height: 13px;

                    svg {
                        vertical-align: top;
                        // margin-top: 2px;
                    }
                }

                .btn-xs {
                    position: absolute;
                    right: 0;
                    line-height: 0;

                    @media (min-width: $media375) {
                        padding: 0px 5px !important;
                        transform: translate(-10px, -5px);

                        .mobile,
                        .desktop {
                            font-size: 8px;
                        }
                    }

                    @media (min-width: $media1440) {
                        padding: 0px 6px !important;
                        transform: translate(-10px, -5px);

                        .mobile,
                        .desktop {
                            font-size: 12px;
                        }
                    }

                    .mobile,
                    .desktop {
                        margin: 0;
                    }

                    .mobile {
                        @media (min-width: $media375) {
                            display: inline;

                        }

                        @media (min-width: $media1440) {
                            display: none;
                        }
                    }

                    .desktop {
                        @media (min-width: $media375) {
                            display: none;
                        }

                        @media (min-width: $media1440) {
                            display: inline;
                        }
                    }
                }
            }

            &__Value {
                @media (min-width: $media375) {
                    margin-left: 15px;
                    font-size: 14px;
                }

                @media (min-width: $media1024) {
                    margin-left: 15px;
                    font-size: 12px;
                }

                @media (min-width: $media1440) {
                    margin-left: 19px;
                    font-size: 14px;
                }

                &--primary {
                    color: $primary-color;
                }
            }
        }
    }
}

.card {
    border: none !important;
    border-radius: $border-radius !important;
    background: linear-gradient(86.2deg, #f8f8f8 4.31%, #f7f7f7 40.59%);

    &-body {
        @media (min-width: $media1024) {
            // padding: 20px !important;
        }

        @media (min-width: $media1440) {
            padding: 25px;
        }

        > .title {
            @media (min-width: $media1440) {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}

.Deposit__RightBlock {
    border-radius: $border-radius;
    background: linear-gradient(57.3deg, #f8f8f8 -9.62%, #f7f7f7 99.29%);

    @media (min-width: $media375) {
        padding: 20px 22px;
        margin-left: 0;
        margin-top: 20px;
    }

    @media (min-width: $media1024) {
        margin-top: 0;
        padding: 20px 22px;
        margin-left: 20px;
    }

    @media (min-width: $media1440) {
        padding: 32px 37px;
        margin-left: 23px;
    }

    > .title {
        @media (min-width: $media375) {
            margin-bottom: 15px;
            font-size: 14px;
        }

        @media (min-width: $media1024) {
            margin-bottom: 15px;
            font-size: 12px;
        }

        @media (min-width: $media1440) {
            margin-bottom: 28px;
            font-size: 14px;
        }

        @media (min-width: $media1920) {
            margin-bottom: 28px;
            font-size: 16px;
        }
    }

    .balance {
        background: linear-gradient(0deg, #f86001 0%, #fea001 93.75%);
        border-radius: $border-radius;
        padding: 11px 20px;
        color: #fff;
        font-weight: 600;
        font-family: 'Vela Sans Regular';

        @media (min-width: $media1024) {
            width: 139px;
        }

        @media (min-width: $media1440) {
            width: 147px;
        }

        @media (min-width: $media1920) {
            width: 197px;
        }

        .title {
            @media (min-width: $media1024) {
                font-size: 12px;
                line-height: 16px;
            }

            @media (min-width: $media1440) {
                font-size: 13px;
                line-height: 17px;
            }

            @media (min-width: $media1920) {
                font-size: 14px;
                line-height: 16px;
            }
        }

        .value {
            @media (min-width: $media1024) {
                font-size: 12px;
                line-height: 16px;
                margin-top: 3px;
            }

            @media (min-width: $media1440) {
                font-size: 14px;
                line-height: 19px;
                margin-top: 3px;
            }

            @media (min-width: $media1920) {
                font-size: 16px;
                line-height: 22px;
                margin-top: 3px;
            }
        }
    }
}

.Counter {
    background: #f0f0f0;
    border-radius: $border-radius;

    @media screen and (min-width: $media375) {
        padding: 11px 11px;
    }

    @media screen and (min-width: $media1024) {
        padding: 11px 17px;
    }

    @media screen and (min-width: $media1440) {
        padding: 13px 20px;
    }

    &__Title {
        color: #000000;

        @media screen and (min-width: $media375) {
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
        }

        @media screen and (min-width: $media1024) {
            font-weight: 500;
            font-size: 11px;
            line-height: 14px;
        }

        @media screen and (min-width: $media1440) {
            font-weight: 500;
            font-size: 13px;
            line-height: 17px;
        }

        &__Buttons {
            display: flex;
            cursor: pointer;
            gap: 5px;
            position: relative;

            > div {
                background: #e4e4e4;
                border-radius: 11px;
                display: flex;
                align-items: center;
                justify-content: center;

                @media screen and (min-width: $media1024) {
                    width: 20px;
                    height: 18px;
                }

                @media screen and (min-width: $media1440) {
                    width: 29px;
                    height: 26px;
                }

                &:nth-child(1) {
                    position: absolute;

                    @media screen and (min-width: $media1024) {
                        top: -2px;
                        right: -10px;
                    }

                    @media screen and (min-width: $media1440) {
                        top: -5px;
                        right: -10px;
                    }
                }

                &:nth-child(2) {
                    position: absolute;

                    @media screen and (min-width: $media1024) {
                        top: 20px;
                        right: -10px;
                    }

                    @media screen and (min-width: $media1440) {
                        top: 24px;
                        right: -10px;
                    }
                }
            }

            img {
                width: 12px;
                height: auto;
            }
        }
    }

    &__Value {
        color: #5b5959;
        font-family: 'Vela Sans Regular';

        @media screen and (min-width: $media375) {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            margin-top: 4px;
        }

        @media screen and (min-width: $media1024) {
            font-weight: 600;
            font-size: 11px;
            line-height: 17px;
            margin-top: 4px;
        }

        @media screen and (min-width: $media1440) {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            margin-top: 4px;
        }

        &-Active {
            color: #ff7a00;
        }

        &-Big {
            @media screen and (min-width: $media1440) {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}

.fast-deposit-wrapper,
.PieChartWrapper {
    @media screen and (min-width: $media375) {
        width: 100%;
    }

    @media screen and (min-width: $media1024) {
        width: auto;
    }
}

.nav-menu {
    @media screen and (min-width: $media375) {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 30px;
        right: 24px;
        height: 33px;
        width: 33px;
        z-index: 3;
    }

    @media screen and (min-width: $media1024) {
        display: none;
    }
}

.mobile-menu-title {
    font-size: 16px;
    margin: 20px 0;
}

.mobile-menu {
    a {
        letter-spacing: -1px;
        align-items: center;

        img {
            width: 79px;
            height: 79px;
        }

        &.selected {
            img {
                border: solid 1px #fff;
                border-radius: $border-radius;
            }
        }
    }
}

.vela-sans {
    font-family: 'Vela Sans Regular';
}

.value.text-success {
    color: #84b900 !important;
}

.notifications-popover {
    margin-top: 10px;
}

.sidebar-links {
    display: flex;
    gap: 13px;

    .btn-secondary {
        padding: 12px 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        font-size: 13px;
        background: #eaeaea;
        color: #000;
        border: none;

        svg {
            height: 20px;
            width: auto;
        }
    }
}

.btn-xs {
    @media screen and (min-width: $media375) {
        padding: 0px 6px !important;
        font-size: 12px !important;
    }

    @media screen and (min-width: $media1440) {
        padding: 2px 8px !important;
        font-size: 12px !important;
    }
}

.gray-block {
    background: #e4e4e4;
    border-radius: $border-radius;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #000;
    text-decoration: none;

    img {
        width: 25px;
        height: 25px;
    }

    .subtitle {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #3a3a3a;
    }

    .name {
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (min-width: $media375) {
            font-size: 13px;
            line-height: 16px;
        }

        @media (min-width: $media1024) {
            font-size: 11px;
            line-height: 13px;
        }

        @media (min-width: $media1440) {
            font-size: 13px;
            line-height: 16px;
        }
    }

    &.small-block {
        gap: 0;
        flex-direction: column;
        font-size: 13px;
        line-height: 18px;
        padding: 10px 20px;
        // min-height: 59px;

        &.stablecoin {
            position: relative;

            img {
                width: 13px;
                height: 13px;
            }

            .name {
                color: #3a3a3a;
            }

            .value {
                font-size: 13px;
                line-height: 18px;

                // @media (min-width: $media1024) {
                //     font-size: 11px;
                //     line-height: 11px;
                //     margin-top: 0;
                // }

                // @media (min-width: $media1440) {
                //     font-size: 13px;
                //     line-height: 18px;
                //     margin-top: 5px;
                // }

                color: #000000;
            }

            .buttons {
                position: absolute;
                right: 14px;
                top: 8px;
                gap: 5px;

                @media screen and (min-width: $media375) {
                    display: none;
                }

                @media screen and (min-width: $media1024) {
                    display: flex;
                }

                > div,
                > a {
                    cursor: pointer;
                    background: #dadada;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media screen and (min-width: $media1024) {
                        width: 16px;
                        height: 16px;
                    }

                    @media screen and (min-width: $media1440) {
                        width: 18px;
                        height: 18px;
                    }

                    svg {
                        @media screen and (min-width: $media1024) {
                            width: 10px;
                            height: 10px;
                        }

                        @media screen and (min-width: $media1440) {
                            // width: 22px;
                            // height: 22px;
                        }
                    }
                }
            }
        }
    }

    .badge {
        font-size: 10px !important;
        padding: 1px 9px !important;
    }
}

.rounded-4 {
    border-radius: $border-radius;
}
