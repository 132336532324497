@import 'src/variables';

.WalletStatus {
    display: flex;
    align-items: center;
    border-radius: $border-radius;
    justify-content: center;
    align-items: stretch;
    position: relative;

    @media screen and (min-width: $media375) {
        // padding: 20px;
    }

    @media screen and (min-width: $media1024) {
        // padding: 0;
    }

    @media screen and (min-width: $media1440) {
        // width: 358px;
        height: 141px;
    }

    @media screen and (min-width: $media1920) {
        // width: 100%;
        height: 141px;
    }

    &__connected {
        justify-content: space-between;
        padding: 0;

        &.WalletStatus__inner {
            @media screen and (min-width: $media1024) {
                width: calc(100% - 30px);
            }
        }

        .mobile-icon {
            @media screen and (min-width: $media375) {
                display: inline-block;
                margin-right: 5px;
            }

            @media screen and (min-width: $media1024) {
                display: none;
            }
        }

        .state {
            color: #000;

            // @media screen and (min-width: $media375) {
            //     display: none;
            // }

            @media screen and (min-width: $media375) {
                display: block;
                font-size: 12px;
                line-height: 9px;
            }

            @media screen and (min-width: $media1440) {
                font-size: 16px;
                line-height: 14px;
            }

            @media screen and (min-width: $media1920) {
                font-size: 18px;
                line-height: 25px;
            }
        }

        .address {
            color: $primary-color;
            cursor: pointer;

            @media screen and (min-width: $media375) {
                font-size: 13px;
                line-height: 18px;
                // display: none;
            }

            @media screen and (min-width: $media1024) {
                font-size: 12px;
                line-height: 9px;
                display: block;
                margin-top: 10px;
            }

            @media screen and (min-width: $media1440) {
                font-size: 16px;
                line-height: 14px;
                margin-top: 0;
            }

            @media screen and (min-width: $media1920) {
                font-size: 18px;
                line-height: 25px;
            }
        }

        .selector {
            @media screen and (min-width: $media375) {
                display: none;
            }

            @media screen and (min-width: $media1440) {
                margin-left: 15px;
                display: inline-block;
            }
        }

        .btn {
            @media screen and (min-width: $media1024) {
                font-size: 12px !important;
            }

            @media screen and (min-width: $media1440) {
                font-size: 14px !important;
            }
        }
    }

    &__Connect {
        background: #eaeaea;
        border-radius: $border-radius;
        color: #5b5959;
        border: none;
        text-align: center;
        width: 80px;
        margin: 15px auto 0 auto;
        font-size: 11px;
        line-height: 14px;
        text-align: center;
        margin: 10px auto 0 auto;
        padding: 6px 12px;
    }

    &__Icon {
        background: #eaeaea;
        padding: 15px 10px;
        border-radius: $border-radius;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        margin: 0 auto;
        cursor: pointer;
        height: 47px;

        svg {
            height: 24px;
            width: 27px;
        }
    }

    &__toggler {
        background: linear-gradient(179.57deg, #fea601 -4.37%, #f96101 108.65%);
        position: absolute;
        right: 0;
        border-radius: 0 20px 20px 0;
        width: 50px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 11px;
        cursor: pointer;
        transition: all ease-in-out 0.3s;

        @media screen and (min-width: $media375) {
            display: none;
        }

        @media screen and (min-width: $media1024) {
            height: 133px;
            display: flex;
        }

        @media screen and (min-width: $media1440) {
            height: 141px;
        }

        &.opened {
            @media screen and (min-width: $media1024) {
                transform: translateX(50px);
                width: 70px;
                padding-right: 18px;
            }

            @media screen and (min-width: $media1440) {
                transform: translateX(70px);
                width: 100px;
                padding-right: 32px;
            }
        }
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 26px;
        row-gap: 15px;
        background: linear-gradient(81.95deg, #f1f1f1 5.86%, #f3f3f3 93.43%);
        border-radius: $border-radius;

        z-index: 2;

        @media screen and (min-width: $media375) {
            width: 100%;
        }

        > div {
            width: 50%;
        }

        > div.network {
            width: 53%;
        }

        > div.logout {
            width: 47%;
        }

        &__network {
            display: flex;
            gap: 20px;

            svg {
                height: 35px;
                width: auto;
            }

            span {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;

                @media screen and (min-width: $media1920) {
                    width: 100px;
                }
            }
        }

        &__wallet {
            color: #000;
            display: flex;
            flex-direction: column;
            row-gap: 5px;
        }

        .btn-sm {
            background: #ebebeb !important;
            color: #5b5959;
        }
    }
}

#networks-selector {
    background-color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    display: none;

    @media screen and (min-width: $media375) {
        padding: 30px;
    }

    @media screen and (min-width: $media1024) {
        left: 50% !important;
        padding-left: 57px;
        width: calc(1024px - 391px);
        transform: translateX(-50%);
        margin-left: 200px;
    }

    @media screen and (min-width: $media1440) {
        left: 50% !important;
        padding-left: 80px;
        width: calc(1440px - 468px);
        transform: translateX(-50%);
        margin-left: 240px;
    }

    @media screen and (min-width: $media1920) {
        left: 50% !important;
        padding-left: 96px;
        width: calc(1920px - 570px);
        transform: translateX(-50%);
        margin-left: 280px;
    }

    &.active {
        display: block;
        z-index: 1111;
    }

    > .title {
        font-size: 20px;
        line-height: 26px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;

        img {
            width: 26px;
            height: 26px;
        }

        // @media screen and (min-width: $media375) {
        //     display: block;
        // }

        // @media screen and (min-width: $media1024) {
        //     display: none;
        // }
    }

    .networks {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .network-item {
            padding: 30px;
            border-radius: $border-radius;
            background: linear-gradient(70.12deg, #f1f1f1 8.19%, #f3f3f3 88.05%);
            position: relative;

            @media screen and (min-width: $media375) {
                width: calc(100%);
            }

            @media screen and (min-width: $media1024) {
                width: calc(50% - 10px);
            }

            @media screen and (min-width: $media1440) {
                width: calc(50% - 40px);
            }

            .inner {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 20px;

                .title {
                    font-size: 14px;
                    line-height: 18px;
                }

                svg {
                    width: 25px;
                    height: 25px;
                }
            }

            .choose-btn {
                font-size: 13px;
                line-height: 17px;
                background: #ebebeb;
                border-radius: 20px;
                color: #000;
                padding: 6px 16px;
                text-decoration: none;
                border: none;
            }

            .bg {
                position: absolute;
                right: 20px;
                top: 20px;
                bottom: 20px;
                width: 90px;
                pointer-events: none;

                svg {
                    width: 100%;
                    height: 100%;
                    filter: grayscale(1);
                    opacity: 0.2;
                    pointer-events: none;
                }
            }
        }
    }
}
