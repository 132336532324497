@import 'src/variables';

.all-services-panel {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    background-color: #fff;
    z-index: 111111;

    &.active {
        display: block;
    }

    .fast-menu {
        a {
            position: relative;

            span {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                width: 100%;
            }
        }
    }

    .law-links {
        a {
            background: #ebebeb;
            color: #000;
            text-decoration: none;
        }
    }

    .about {
        font-size: 14px;
        line-height: 138.1%;
        width: 466px;
    }

    @media (min-width: $media375) {
        padding: 20px !important;
    }

    @media (min-width: $media1024) {
        padding: 0 35px 20px 30px !important;
    }

    .close {
        position: absolute;
        top: 10px;
        right: 40px;
        cursor: pointer;

        img {
            width: 26px;
            height: 26px;
        }
    }
}

#sidebar-col.transparent {
    background: none !important;

    &::after {
        background: none !important;
    }
}

#nav-menu.hidden {
    opacity: 0;
    pointer-events: none;
}

.official-links {
    a {
        font-size: 15px;
        line-height: 19px;
        text-underline-offset: 3px;
        color: #444444;
        text-decoration-color: #444444;

        span {
            margin-left: 5px;
        }
    }
}

.media-links {
    a {
        width: 244px;
        height: 93px;
        text-decoration: none;
        color: #000;
        border-radius: $border-radius;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        line-height: 20px;
        padding-left: 30px;

        &:nth-child(1) {
            background: linear-gradient(55.16deg, #eef4ff 21.38%, #f8f8f8 68.33%);
        }

        &:nth-child(2) {
            background: linear-gradient(53.71deg, #e3e5fa 15.83%, #f9f9f9 65.73%);
        }

        &:nth-child(3) {
            background: linear-gradient(50.03deg, #f4f4f4 21.18%, #f5f5f5 65.78%);
        }

        &:nth-child(4) {
            background: linear-gradient(47.54deg, #effbff 20.54%, #f7f7f7 70.21%);
        }

        .muted {
            font-size: 14px;
            line-height: 18px;
            color: #4b4b4b;
            margin-top: 3px;
        }

        svg {
            @media (min-width: $media1024) {
                margin-right: 10px;
            }
        }
    }
}
