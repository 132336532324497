@import 'src/variables';

.ThemeSwitcher {
    cursor: pointer;
    background-color: $btn-secondary-color;
    border-radius: $border-radius;
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: $media1024) {
        padding: 8px 14px;
    }

    @media screen and (min-width: $media1440) {
        padding: 10px 14px;
    }

    span {
        @media screen and (min-width: $media375) {
            display: inline;
        }

        @media screen and (min-width: $media1024) {
            display: none;
        }
    }
}
