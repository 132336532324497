@import 'src/variables';

body.dark {
    .DaoDelegationModal {
        .modal-content {
            border-color: rgba(255, 255, 255, .4)
        }   
    }
}

.DaoDelegationModal {
    .modal-header {
        border: none;
    }

    .modal-dialog {
        max-width: 643px !important;

        .modal-content {
            border: solid 1px #dedede;
            border-radius: 20px;
            overflow: hidden;
            padding: 20px;

            @media (min-width: $media375) {
                width: 100%;
                height: auto;
                text-align: center;
            }
    
            @media (min-width: $media1024) {
                width: 643px;
                height: 349px;
                text-align: left;
            }

            .btn-secondary {
                padding: 10px 30px;
                font-size: 13px;
            }
        }
    }

    p {
        font-weight: 500;
        font-size: 14px;
        color: #636363;
        margin-bottom: 20px;
    }

    img.shark {
        width: 175px;
        height: 238px;
        margin-left: 60px;
    }

    .disabled-section {
        width: 183px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1.1px solid #F4EFE1;
        border-radius: 5px;

        @media (min-width: $media375) {
            margin-left: auto;
            margin-right: auto;
        }

        @media (min-width: $media1024) {
            margin-left: 0;
        }

        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: #636363;
            margin-left: 5px;
        }
    }
}