@import 'src/variables';

.CoinSwapPanel {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .gray-block {
        align-items: flex-start;
        flex-direction: column;
    }

    .input-wrapper {
        background-color: #D4D4D4;
        border-radius: 20px;
        position: relative;
        padding: 8px;

        input {
            background-color: transparent;
            border: none;
            width: 100%;
            padding-right: 50px;
            padding-left: 10px;

            &:active,
            &:focus {
                border: none;
                outline: none;
            }
        }

        .max {
            position: absolute;
            right: 10px;
            left: auto;
            top: 50%;
            transform: translateY(-50%);
            border: solid 1px #FF7A00;
            color: #FF7A00;
            background-color: transparent;
            border-radius: 20px;
            font-size: 12px;
            text-transform: uppercase;
            padding: 4px 8px;
        }
    }

    .swap-btn {
        background-color: #dadada;
        border-radius: 100px;
        position: absolute;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(-50%);
        top: 50%;
        right: 20px;
        // margin-top: -10px;
        cursor: pointer;
    }

    .curr-slippage {
        font-size: 10px;
        min-width: 80px;
    }

    .slippage-option {
        font-size: 12px;
        border: solid 1px transparent;
        border-radius: 10px;
        background-color: #D8D8D8;
        padding: 2px 10px;
        color: #898787;

        &.active {
            border-color: #898787;
        }
    }

    .dropdown-toggle {
        padding: 8px 16px;
        background-color: #D4D4D4;
        color: #000;
        border: none;
        font-size: 14px;
        line-height: 14px;
        width: 130px;

        &::after {
            display: none;
        }

        &:hover,
        &:active,
        &:focus {
            background-color: #D4D4D4;
            color: #000;
            box-shadow: none !important;
            outline: none;
            border: none;
        }
    }

    .dropdown-menu {
        background-color: #D4D4D4;
        border-radius: 20px;
        overflow: hidden;
    }

    .dropdown-item {
        font-size: 14px;
        display: flex;
        align-items: center;
        background-color: #D4D4D4;

        img {
            height: 20px;
            width: 20px;
        }
    }
}

body.dark {
    .CoinSwapPanel {
        .input-wrapper {
            background-color: #484848;

            input {
                background-color: #484848 !important;
            }


        }

        .swap-btn {
            background-color: #3B3B3B;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
        }

        .dropdown-toggle {
            background: #484848 !important;
            border: none;
            min-width: 150px;
        }

        .dropdown-item,
        .dropdown-menu {
            background: #484848;
            color: #fff;
        }

        .curr-slippage {
            color: #fff;
        }

        .zun.slippage-option {
            background-color: #484848;
            color: #fff;
        }
    }
}