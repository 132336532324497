@import 'src/variables';

.row,
.col,
.row > * {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.dashboard-col {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: stretch;

    @media screen and (min-width: $media375) {
        padding-left: 19px !important;
        padding-right: 19px !important;
    }

    @media screen and (min-width: $media1024) {
        width: 640px !important;
        max-width: 640px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    @media screen and (min-width: 1200px) {
        width: 826px !important;
        max-width: 826px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    @media screen and (min-width: 1400px) {
        width: 900px !important;
        max-width: 900px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    @media screen and (min-width: $media1440) {
        width: 987px !important;
        max-width: 987px !important;
        min-width: 987px !important;
    }

    @media screen and (min-width: $media1920) {
        width: 1312px !important;
        max-width: 1312px !important;
        min-width: 1312px !important;
    }

    .first-row {
        @media screen and (min-width: $media1024) {
            margin-top: 27px;
        }

        @media screen and (min-width: $media1440) {
            margin-top: 77px;
        }

        .InfoBlock {
            @media screen and (min-width: $media375) {
                height: 70px;
            }

            @media screen and (min-width: $media1024) {
                width: 142px;
                height: 82px;
            }

            @media screen and (min-width: $media1440) {
                width: 247px;
                height: 82px;
            }

            @media screen and (min-width: $media1920) {
                width: 290px;
                height: 97px;
            }

            &:nth-child(2) {
                @media screen and (min-width: $media1024) {
                    width: 268px;
                    height: 82px;
                }

                @media screen and (min-width: $media1440) {
                    width: 322px;
                    height: 82px;
                }

                @media screen and (min-width: $media1920) {
                    width: 378px;
                    height: 97px;
                }
            }
        }
    }

    .second-row {
        @media screen and (min-width: $media375) {
            flex-wrap: nowrap !important;
        }

        @media screen and (min-width: $media1024) {
            margin-bottom: 14px;
            height: 82px;
        }

        @media screen and (min-width: $media1440) {
            margin-bottom: 14px;
            height: 82px;
        }

        @media screen and (min-width: $media1920) {
            margin-bottom: 16.4px;
            height: 82px;
        }
    }

    .first-row,
    .second-row,
    .third-row {
        display: flex;

        @media screen and (max-width: 1023px) {
            flex-wrap: wrap;
        }

        @media screen and (min-width: $media1024) {
            padding-left: 0;
            flex-wrap: nowrap;
            margin-left: 30px;
            margin-right: 40px;
        }

        @media screen and (min-width: $media1440) {
            padding-left: 0;
            margin-left: 40px;
            margin-right: 40px;
        }

        @media screen and (min-width: $media1920) {
            padding-left: 0;
            margin-left: 47px;
            margin-right: 275px;
        }

        .InfoBlock {
            @media screen and (max-width: 1023px) {
                margin-bottom: 11px;
            }
        }
    }

    .first-row .InfoBlock:first-child,
    .second-row .InfoBlock:first-child {
        margin-left: 0;

        @media screen and (max-width: 1023px) {
            width: calc(100% - 22px);
            margin-left: 11px;
        }
    }

    .first-row .InfoBlock:nth-child(2),
    .first-row .InfoBlock:last-child,
    .second-row .InfoBlock:nth-child(2),
    .second-row .InfoBlock:last-child {
        @media screen and (max-width: 1023px) {
            width: calc(100% - 22px);
        }
    }

    .first-row .InfoBlock:last-child,
    .second-row .InfoBlock:last-child {
        @media screen and (max-width: 1023px) {
            margin-left: 10px;
        }
    }

    .first-row .InfoBlock:last-child,
    .second-row .InfoBlock:last-child {
        margin-right: 0;
    }

    .third-row {
        flex-grow: 1;
    }
}

.dashboard-col .wrapper {
    margin: 0 0 0 0;
    position: relative;
    display: flex;

    > img {
        width: 20px;
        height: 20px;
        transform: translateY(-4px);
    }

    .coin {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;

        > img,
        > svg {
            width: 22px;
            height: 22px;
            
            // &.fill {
            //     background-color: #fff;
            //     border-radius: 100%;
            // }
        }

        &:last-child {
            transform: translateX(-10px);
        }
    }
}

.bottom-links {
    justify-content: center;
}

.dashboard-col .strats-chart-col,
.dashboard-col .hist-apy-col {
    background-color: #fff;
    width: 50%;
    border-radius: 5px;
    overflow: hidden;

    @media screen and (max-width: 1023px) {
        width: 100%;
        height: auto;
        margin-top: 10px;
    }

    @media screen and (min-width: $media1024) {
        height: 270px;
        width: 285px;
    }

    @media screen and (min-width: $media1440) {
        height: 229px;
        width: 416px;
    }

    @media screen and (min-width: $media1920) {
        height: 269px;
        width: 489px;
    }
}

.dashboard-col .strats-chart-col {
    @media screen and (min-width: $media1024) {
        margin-right: 10px;
    }

    @media screen and (min-width: $media1440) {
        width: 416px;
        height: 229px;
    }

    @media screen and (min-width: $media1920) {
        margin-right: 15.2px;
        height: 269px;
        width: 489px;
    }
}

.main-row.row {
    @media screen and (max-width: 1023px) {
    }

    @media screen and (min-width: $media1024) {
    }

    @media screen and (min-width: $media1440) {
    }
}

.divider {
    background: #e9e9e9;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.popover {
    background: linear-gradient(86.06deg, #f1f1f1 4.87%, #f4f4f4 98.87%);
    border-radius: $border-radius !important;
    border: none !important;
    overflow: hidden;
    box-shadow: 0 0 10px #dedede;

    .balances {
        display: flex;
        gap: 10px;
    }

    .balance {
        background: #ebebeb;
        padding: 20px;
        border-radius: $border-radius;
        display: flex;

        svg {
            width: 28px;
            height: 28px;
        }

        .chain {
            margin-left: 10px;
            font-size: 14px;
            line-height: 18px;
        }

        .sum {
            font-family: 'Vela Sans Regular';
            font-size: 16px;
            line-height: 22px;
            margin-top: 5px;
            margin-left: 10px;
        }
    }
}

@media screen and (max-width: 1023px) {
    .popover {
        transform: translate3d(20px, 464px, 0px) !important;
        max-width: calc(100% - 40px) !important;

        .balances {
            flex-wrap: wrap;

            .balance {
                width: 140px;

                .chain {
                    font-size: 12px;
                }
            }

            .chain {
                height: 20px;
                overflow: hidden;
            }
        }
    }
}

@media screen and (min-width: $media1024) {
    .popover {
        max-width: 780px !important;

        .balance {
            width: 225px;
        }

        .chain {
            height: auto;
        }
    }
}
