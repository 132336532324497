@import 'src/variables';

.NetworkSelector {
    // background: #f1f1f1;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    // gap: 5px;

    @media screen and (min-width: $media375) {
        width: 55px;
        height: 45px;
        margin-left: 10px;
        margin-right: 13px;
    }

    @media screen and (min-width: $media1024) {
        width: 55px;
        height: 31px;
        margin-left: 0;
        margin-right: 13px;
    }

    @media screen and (min-width: $media1440) {
        width: 126px;
        height: 33px;
        margin-left: 20px;
        margin-right: 0;
    }

    @media screen and (min-width: $media1920) {
    }

    span {
        @media screen and (min-width: $media375) {
            display: none;
        }

        @media screen and (min-width: $media1024) {
            font-size: 12px;
            line-height: 16px;
            display: none;
        }

        @media screen and (min-width: $media1440) {
            margin: 0 10px;
            display: block;
        }

        @media screen and (min-width: $media1920) {
        }
    }

    svg {
        @media screen and (min-width: $media375) {
            width: 16px;
            height: 23px;
        }

        @media screen and (min-width: $media1024) {
            width: auto;
            height: 17px;
        }

        @media screen and (min-width: $media1440) {
            width: 11px;
            height: 18px;
        }

        &.selector {
            width: 11px;
            height: auto;


        }
    }

    &__Toggler {
        @media screen and (min-width: $media375) {
            display: none;
        }

        @media screen and (min-width: $media1440) {
            display: block;
        }
    }

    select {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        cursor: pointer;
    }
}
