$panel-bg-color: #ebebeb;
$text-color: #5B5B5B;
$secondary-color: #9E9D9D;
$light-color: #F4F4F4;
$primary-color: #FC8E04;
$color-white: #ffffff;
$pill-bg-color: #e4e4e4;
$warning-color: #F5E8B3;
$detailsBtnBgColor: #D7D7D7;

.bg-body-tertiary {
    background-color: $panel-bg-color;
    color: $text-color;
}

.bg-light {
    background-color: $light-color;
}

.btn {
    border-radius: 7px !important;
}

.btn-primary {
    background-color: $primary-color;
    border-color: transparent;

    &:hover {
        background-color: #414141;
        border-color: transparent;
    }
}

nav.fixed-top {
    border-radius: 20px;
}

.nav-pills {
    .nav-link {
        color: $text-color;
        font-size: 15px;
        padding: 0.2rem 1rem;
        border-radius: 10px;
    
        &.active {
            background-color: $primary-color;
            color: $color-white;
        }
    }
}

.dropdown {
    .btn-link {
        color: $text-color;
        text-decoration: none;
    }
}

input[type='text'].form-control {
    width: auto;
    border-radius: 20px;
    background-color: $pill-bg-color;
}

.text-bg-secondary {
    background-color: $pill-bg-color;
    color: $text-color;
    font-size: 14px;
}

.text-bg-primary {
    background-color: $primary-color;
    color: $color-white;
}

.text-bg-warning {
    background-color: $warning-color;
    color: #B3882B;
}

body {
    font-family: 'Space Grotesk';
}

h4 {
    color: $text-color;
    font-size: 22px;
}

h5 {
    color: $text-color;
    font-size: 15px;
    font-weight: 600;
}

.rounded3-5 {
    border-radius: 17px;
}

#gas-btn, #wallet-btn {
    color: $text-color;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

#gas-btn {
    pointer-events: none;
}

.navbar-v2 {
    #nav-menu {
        border-radius: 17px;

        ul {
            width: 100%;

            li {
                width: 25%;
                text-align: center;
            }
        }
    }
}



.filter {
    cursor: pointer;
    text-transform: capitalize;
    font-size: 12px !important;
    padding: 5px 17px !important;
}

.back-btn-row {
    color: $secondary-color;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;

    .back-btn {
        width: 21px;
        height: 21px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $detailsBtnBgColor;
    }

    span {
        text-decoration: none;
    }
}

.state {
    height: 26px;
    border-radius: 50px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: auto;
    font-size: 12px;
    padding: 4px 12px 6px 12px;
    text-transform: capitalize;

    .orb {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        margin: 0 5px 0 0;
        line-height: 8px;
        transform: translateY(-1px);
    }

    &.active,
    &.succeeded,
    &.queued,
    &.executed {
        background-color: #8cdb2c91;
        color: #2F3F2D;

        .orb {
            background-color: #8DDB2C;
        }
    }

    &.passed {
        background-color: #B6D9E6;
        color: #2F3F2D;

        .orb {
            background-color: #2C81DB;
        }
    }

    &.defeated {
        background-color: #dba42c6c;
        color: #2F3F2D;

        .orb {
            background-color: #DBA42C;
        }
    }
}

.subtitle {
    color: $text-color;
    font-size: 14px;
}

.title {
    color: $text-color;
    font-size: 14px;

    &.big {
        font-size: 22px;
    }
}

.progress {
    background-color: #2F427B;
    height: 10px;
    border-radius: 10px;
    overflow: visible;

    .progress-bar {
        background-color: #5279ED;
        border-radius: 10px;
    }

    .quorum {
        width: 3px;
        border-radius: 5px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        background-color: #444444;
    }

    &.support {
        background-color: #DF4E4E;

        .progress-bar {
            background-color: #6BDF4E;
            border-radius: 10px 0 0 10px;
        }
    }
}

.current-percent {
    color: #5279ED;
}

.label {
    color: $text-color;
    font-size: 14px;
}

.proposal {
    background-color: $pill-bg-color;
    border-radius: 10px;
    padding: 25px;

    .description {
        color: $text-color;
        font-size: 12px;
    }

    .more-link {
        width: 33px;
        height: 33px;
        background-color: $detailsBtnBgColor;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        cursor: pointer;
    }
}

.actions {
    .btn {
        border-radius: 8px !important;
        width: 50%;
        min-height: 45px;
    }
}

#bottom-disclaimer {
    font-size: 12px;
    color: $secondary-color;
    font-weight: 600;

    a {
        color: $secondary-color;
        text-decoration: none;
    }
}

#copyright {
    font-size: 12px;
    color: $secondary-color;
}

#backers {
    .backer {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background-color: #ECECEC;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        padding: 5px;

        img {
            max-width: 100%;
            height: auto;
        }
    }
}

#sidebar-accordion {
    .accordion-item {
        border-left: none;
        border-right: none;
        border-radius: 0;

        .accordion-button {
            color: $secondary-color;
            font-size: 12px;
            padding: 15px 0;

            &:focus {
                border-color: transparent;
                box-shadow: none;
            }

            &::after {
                width: 13px;
                height: 13px;
                background-size: 13px;
                opacity: .5;
                margin-right: 10px;
            }

            &:not(.collapsed) {
                background-color: transparent;
                box-shadow: none;
                border-radius: 0;
            }
        }
    }
}

#selected-proposal {
    #proposal-wrapper {
        background-color: $panel-bg-color;
        border: solid 1px #E6E6E6;
        padding: 30px;
        border-radius: 5px;
    }

    #votes {
        thead {
            th {
                color: rgba(0, 0, 0, .5);
                font-size: 14px;
                padding-bottom: 10px;
                border-bottom: solid 1px rgba(0, 0, 0, .1);
            }
        }

        tbody {
            tr {
                &:first-child {
                    td {
                        padding-top: 20px;
                    }
                }
            }
        }
    }
}

#new-proposal {
    h2 {
        color: $text-color;
        font-size: 22px;
    }

    p, label {
        color: $text-color;
    }

    .panel {
        background-color: $panel-bg-color;
        border: solid 1px #E6E6E6;
    }

    .divider {
        height: 1px;
        background-color: $detailsBtnBgColor;
    }

    .form-control {
        border-radius: 10px;
        background-color: #fff;
    }

    .action-buttons {
        .btn {
            padding: 10px 25px;
        }
    }

    textarea {
        resize: none;
    }

    .param {
        .dropdown {
            color: #fff;
            
            button {
                padding: 6px 30px;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
        }

        .remove-item-btn {
            cursor: pointer;
        }
    }

    .btn-primary {
        &.disabled {
            background-color: #fc8e049c;
            border-color: #fc8e049c;
        }

        &:focus, &:active {
            background-color: #d77902;
        }
    }
}

.sidebar-link {
    border-radius: 7px;
    background-color: #E9E9E9;
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 5px;
    padding: 6px 20px;
    text-decoration: none;
    color: #5B5B5B;
}

    .offcanvas {
        background-color: #E9E9E9;

        .nav-link {
            border-radius: 7px;
            padding: 10px 18px;
            margin-bottom: 10px;
            text-align: center;
            background-color: #DFDFDF;
            color: #5B5B5B;
        }
    }

body.dark {
    .inverse {
        filter: invert(1);
    }

    #backers {
        .backer {
            background-color: #2F2F2F;
        }
    }
    .offcanvas {
        background-color: #5B5B5B;

        svg {
            path {
                fill: #fff;
            }
        }

        .nav-link {
            background-color: #716F6F;
            color: #fff;
        }

        .btn-close {
            filter: invert(1);
        }
    }

    .bg-body-tertiary {
        background-color: $text-color;
    }

    .sidebar-link {
        background-color: #1B1B1B;
        color: #fff;

        svg {
            path, rect {
                stroke: #fff;
            }
        }
    }

    .navbar-v2 {
        #nav-menu {
            background-color: #2C2C2C;

            li {
                a {
                    color: #fff;
                }
            }
        }
    }

    .bg-light {
        background-color: #1a1a1a !important;
    }

    h1, h2, h3, h4 {
        color: #fff;
    }

    .text-bg-secondary {
        background-color: #333232;
    }

    .dropdown-toggle.btn.btn-link {
        color: #fff;
    }

    .proposal {
        background-color: rgba(38, 38, 38, 0.52);

        h5 {
            color: #E0E0E0;
        }
    }

    .state.active {
        background-color: #3F3B2D;
    }

    .more-link {
        background-color: #3b3b3b;
    }

    .accordion-item {
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.2);

        .accordion-button {
            background-color: transparent;

            &::after {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nI2ZmZmZmZic+PHBhdGggZmlsbC1ydWxlPSdldmVub2RkJyBkPSdNMS42NDYgNC42NDZhLjUuNSAwIDAgMSAuNzA4IDBMOCAxMC4yOTNsNS42NDYtNS42NDdhLjUuNSAwIDAgMSAuNzA4LjcwOGwtNiA2YS41LjUgMCAwIDEtLjcwOCAwbC02LTZhLjUuNSAwIDAgMSAwLS43MDh6Jy8+PC9zdmc+");
            }
        }

        .accordion-body {
            color: #757575;
        }
    }

    .social-icons {
        rect {
            fill: #333232;
        }
    }

    .btn-white {
        background-color: #545454;
        color: #fff
    }

    .open-prop-btn {
        height: 45px;
    }

    #new-proposal {
        textarea {
            background-color: #404040;
            border-color: #404040;
        }

        h1, h2, p, label {
            color: #fff;
        }

        .panel {
            background-color: #202020;
            border-radius: 20px;
            border-color: #303030;
        }

        .card-body {
            border-radius: 20px;
        }

        .btn-secondary {
            background-color: #575757 !important;
        }

        input[type="text"], textarea {
            color: #fff !important;
        }
    }

    .back-btn {
        background-color: #3B3B3B;
    }

    .navbar-brand {
        svg {
            path {
                fill: #fff;
            }
        }
    }

    .navbar-toggler {
        svg {
            path {
                stroke: #fff;
            }
        }
    }

    #selected-proposal {
        #proposal-wrapper {
            background-color: #262626 !important;
            border-color: #292929;
        }

        .subtitle {
            color: #b3b3b3;
        }

        #calldata-content {
            background-color: #242424;
        }
    }


}