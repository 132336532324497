@import 'src/variables';

.Preloader {
    display: flex;
    align-items: center;
    line-height: 0;

    img {
       width: 32px;
       height: 32px;
    }
    span {
        @media screen and (min-width: $media375) {
            font-size: 14px;
            line-height: 14px;
            margin-left: 10px;
        }

        @media screen and (min-width: $media1024) {
            font-size: 12px;
            line-height: 12px;
        }
    }

    &.blocker {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        line-height: 0;
        position: fixed;
        background: rgba(255,255,255,0.8);
        backdrop-filter: saturate(180%) blur(10px);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 11111;

        &.round {
            border-radius: $border-radius;
        }

        img {
            width: 128px;
            height: 128px;
        }
    }
}
