@import 'src/variables';

.FastDepositInput {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    position: relative;

    @media screen and (min-width: $media375) {
        // height: 55px;
        width: 200px;
        margin-top: 30px;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
    }

    @media screen and (min-width: $media1024) {
        width: auto;
        height: 30px;
        margin-top: 30px;
        margin-bottom: 20px;
        margin-left: 0;
        justify-content: flex-start;
    }

    @media screen and (min-width: $media1440) {
        height: 42px;
        margin-top: 26px;
        margin-bottom: 20px;
    }

    @media screen and (min-width: $media1920) {
        height: 49px;
        margin-top: 31px;
        // margin-bottom: 31px;
    }

    @media screen and (min-width: $media1920) {
    }

    // .selector-wrapper {
    //     background: #f0f0f0;
    //     border-radius: $border-radius;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     position: relative;

    //     @media screen and (min-width: $media375) {
    //         width: 164px;
    //         height: 42px;
    //         margin-bottom: 20px;
    //     }

    //     @media screen and (min-width: $media1024) {
    //         width: 116px;
    //         height: 30px;
    //         margin-bottom: 0;
    //     }

    //     @media screen and (min-width: $media1440) {
    //         width: 163px;
    //         height: 42px;
    //     }

    //     @media screen and (min-width: $media1920) {
    //         width: 192px;
    //         height: 49px;
    //     }

    //     img {
    //         @media screen and (min-width: $media375) {
    //             width: 23px;
    //             height: 23px;
    //             margin-right: 8px;
    //         }

    //         @media screen and (min-width: $media1024) {
    //             width: 23px;
    //             height: 23px;
    //             margin-right: 8px;
    //         }

    //         @media screen and (min-width: $media1440) {
    //             width: 21px;
    //             height: 21px;
    //             margin-right: 16px;
    //         }

    //         @media screen and (min-width: $media1920) {
    //             width: 20px;
    //             height: 20px;
    //             margin-right: 8px;
    //         }
    //     }

    //     .coinName {
    //         font-family: 'Space Grotesk';

    //         @media screen and (min-width: $media375) {
    //             font-size: 14px;
    //             line-height: 16px;
    //             width: 45px;
    //         }

    //         @media screen and (min-width: $media1024) {
    //             font-size: 11px;
    //             line-height: 15px;
    //             width: 40px;
    //         }

    //         @media screen and (min-width: $media1440) {
    //             font-size: 13px;
    //             line-height: 17px;
    //             width: 50px;
    //         }

    //         @media screen and (min-width: $media1920) {
    //             font-size: 14px;
    //             line-height: 18px;
    //             width: 80px;
    //         }
    //     }
    // }

    select {
        position: absolute;
        top: 50%;
        left: 25px;
        opacity: 0;
        height: 100%;
        transform: translateY(-50%);

        @media screen and (min-width: $media1024) {
            width: 90px;
        }

        @media screen and (min-width: $media1440) {
            width: 120px;
        }

        @media screen and (min-width: $media1920) {
            width: 150px;
        }
    }

    .selector {
        @media screen and (min-width: $media375) {
            // margin-left: 10px;
            // margin-right: 20px;
        }

        @media screen and (min-width: $media1440) {
            margin-left: 10px;
            margin-right: 5px;
        }

        @media screen and (min-width: $media1920) {
            margin-left: 10px;
            margin-right: 20px;
        }
    }

    .max {
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #f95404;
        color: #f95404;
        cursor: pointer;
        border-radius: $border-radius;

        &:hover {
            background-color: #f95404;
            color: #fff;
        }

        @media screen and (min-width: $media375) {
            font-size: 12px;
            line-height: 18px;
            width: 50px;
            height: 34px;
            position: absolute;
            right: 0;
            top: 87px;
        }

        @media screen and (min-width: $media1024) {
            position: static;
            font-size: 10px;
            line-height: 13px;
            padding: 8px 13px;
            height: auto;
            width: auto;
        }

        @media screen and (min-width: $media1440) {
            font-size: 13px;
            line-height: 17px;
            padding: 12px 15px;
        }

        @media screen and (min-width: $media1920) {
            font-size: 14px;
            line-height: 18px;
            padding: 12px 15px;
            width: 86px;
        }
    }

    input {
        background: #f0f0f0;
        border: 1px solid #f4efe1;
        box-sizing: border-box;
        border-radius: $border-radius;
        color: $primary-color;

        @media screen and (min-width: $media375) {
            font-size: 16px;
            line-height: 22px;
            padding: 6px 90px 6px 20px;
            width: 221px;
            height: 56px;
            margin-left: 0;
            margin-right: 0;
        }

        @media screen and (min-width: $media1024) {
            font-size: 11px;
            line-height: 15px;
            width: 75px;
            height: auto;
            margin-left: 12px;
            margin-right: 11px;
            padding: 11px 20px 6px 20px;
        }

        @media screen and (min-width: $media1200) {
            width: 110px;
        }

        @media screen and (min-width: $media1440) {
            font-size: 14px;
            line-height: 19px;
            padding-left: 15px;
            width: 170px;
            margin-left: 12px;
            margin-right: 13px;
        }

        @media screen and (min-width: $media1920) {
            font-size: 14px;
            line-height: 19px;
            padding-left: 15px;
            width: 214px;
            margin-left: 13px;
            margin-right: 13px;
        }
    }
}
