@import 'src/variables';

.PieChart2 {
    position: relative;

    @media (min-width: $media375) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
    }

    @media (min-width: $media1024) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }

    &__Header {
        @media (min-width: $media375) {
            margin: 20px 0 0 10px;
            width: 100%;
            font-size: 12px;
        }

        @media (min-width: $media1024) {
            font-size: 11px;
            width: 100%;
            margin: 17px 0 15px 17px;
        }

        @media (min-width: $media1440) {
            font-size: 12px;
            margin: 23px 0 20px 33px;
        }

        @media (min-width: $media1920) {
            font-size: 12px;
            margin: 27px 0 20px 39px;
        }
    }

    .PieChart__Tooltip {
        position: absolute;
        font-size: 12px;
        text-align: left;
        // width: 110%;
        bottom: auto;
        background-color: #FFF;
        padding: 10px;
        border-radius: 20px;
        transform: translateY(-100px);

        &:empty {
            display: none;
        }

        .circle {
            width: 6px;
            height: 6px;
            margin: 6px 10px 0 6px;
            border-radius: 20px;
        }

        .platform {
            font-weight: 600;
        }

        .pool {
            opacity: .7;
        }
    }

    .PieChartWrapper {
        position: relative;
        display: flex;
        align-content: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 30px;

        @media (min-width: $media375) {
            width: 82px;
            margin-top: 30px;
        }

        @media (min-width: $media1024) {
            width: 117px;
            margin-top: 5px;
        }

        @media (min-width: $media1440) {
            width: 110px;
            margin-top: 16px;
        }

        @media (min-width: $media1920) {
        }

        .PieChart__Chart {
            @media (min-width: $media375) {
                width: 82px;
                height: 82px;
            }

            @media (min-width: $media1024) {
                width: 94px;
                height: 94px;
            }

            @media (min-width: $media1440) {
                width: 94px;
                height: 94px;
            }

            @media (min-width: $media1920) {
                width: 109px;
                height: 109px;
            }
        }

        &__Legend {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;

            &__Label {
                color: $muted-color;
                font-size: 14px;

                @media (min-width: $media375) {
                    font-size: 8px;
                    line-height: 11px;
                }

                @media (min-width: $media1024) {
                    font-size: 8px;
                    line-height: 11px;
                }

                @media (min-width: $media1440) {
                    font-size: 8px;
                    line-height: 14px;
                }

                @media (min-width: $media1920) {
                    font-size: 8px;
                    line-height: 11px;
                }
            }

            &__Counter {
                color: $primary-color;

                @media (min-width: $media375) {
                    font-size: 15px;
                    line-height: 20px;
                    margin-bottom: 0px;
                    margin-top: -5px;
                }

                @media (min-width: $media1024) {
                    font-size: 15px;
                    line-height: 20px;
                    margin-bottom: 0;
                    margin-top: -5px;
                }

                @media (min-width: $media1440) {
                    font-size: 15px;
                    line-height: 20px;
                    margin-bottom: 0;
                    margin-top: 0;
                }

                @media (min-width: $media1920) {
                    font-size: 15px;
                    line-height: 20px;
                    margin-bottom: 0;
                    margin-top: 0;
                }
            }
        }
    }

    .PieChart__StratList {
        @media (min-width: $media375) {
            margin-bottom: 0;
        }

        @media (min-width: $media1024) {
            margin-bottom: 0;
        }

        @media (min-width: $media1440) {
        }

        @media (min-width: $media1920) {
        }

        .PieChart__StratList__Item {
            display: flex;
            flex-direction: row;
            align-items: center;

            @media (min-width: $media375) {
                margin-bottom: 10px;
                font-size: 12px;
                line-height: 16px;
            }

            @media (min-width: $media1024) {
                margin-bottom: 10px;
                font-size: 11px;
                line-height: 15px;
            }

            @media (min-width: $media1440) {
                font-size: 12px;
                line-height: 16px;
            }

            @media (min-width: $media1920) {
                font-size: 12px;
                line-height: 16px;
            }

            &__Circle {
                @media (min-width: $media375) {
                    width: 5px;
                    height: 5px;
                    border-radius: 6px;
                    margin-right: 10px;
                }

                @media (min-width: $media1024) {
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    margin-right: 10px;
                }

                @media (min-width: $media1440) {
                    width: 7px;
                    height: 7px;
                    margin-right: 12px;
                }

                @media (min-width: $media1920) {
                    width: 14px;
                    height: 14px;
                    margin-right: 15px;
                }
            }

            a {
                color: #000;
                text-decoration: none;
            }

            .new-coin {
                .wrapper {
                    margin: 0 0 0 0;

                    > img {
                        width: 17px;
                        height: 17px;
                    }

                    .coin {
                        width: 11px;
                        height: 11px;
                        top: 16px;
                        left: -3px;
                        background: #D7D7D7;
        
                        &:nth-child(2) {
                            left: 9px;
                        }

                        > img,
                        > svg {
                            width: 8px;
                            height: 8px;
                        }
                    }
                }
            }
        }
    }
}
