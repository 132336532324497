@import 'src/variables';

.WalletsModalWrapper {
    @media screen and (min-width: $media375) {
        .badge {
            padding: 6px 12px;
            font-size: 11px !important;
            line-height: 14px;
            text-align: center;
            margin-top: 25px;
        }
    }

    @media screen and (min-width: $media1024) {
        .badge {
            padding: 6px 12px;
            font-size: 11px !important;
            line-height: 14px;
            text-align: center;
            margin-top: 25px;
        }
    }

    @media screen and (min-width: $media1024) {
        .modal-dialog {
            min-width: 581px;
        }
    }
    .modal-header {
        font-size: 20px;
        line-height: 26px;
        font-family: 'Space Grotesk';
        border-radius: 30px 30px 0 0;
        border-bottom: none !important;

        .modal-title {
            margin-left: 50px;
        }

        .btn-close {
            transform: none;
        }
    }

    .modal-content {
        border-radius: 30px;
    }
}
.WalletsModal {
    button {
        width: 126px;
        height: 153px;
        border-radius: 20px !important;
        border: none;
        padding: 15px;
        align-items: flex-start;

        .name {
            font-family: 'Space Grotesk';
            font-size: 14px;
            line-height: 18px;
            text-align: left;
            margin-right: 20px;
            margin-top: 15px;
        }

        .connect {
            background: #fff;
            color: #000;
            border-radius: $border-radius;
            padding: 6px 12px;
            font-size: 11px;
            line-height: 14px;
            text-align: center;
            margin-top: 25px;
        }

        img {
            width: auto;
            height: 22px;
        }

        &.metamask {
            background: linear-gradient(255.16deg, #ffb800 3.65%, #ffa800 94.22%);
            color: #fff;
        }

        &.walletconnect {
            background: linear-gradient(80.97deg, #0e75fe 6.89%, #4c95f7 138.91%);
            color: #fff;
        }

        &.coinbase {
            background: linear-gradient(78.23deg, #f9f9f9 6.99%, #fbfbfb 98.23%);

            span {
                color: #000 !important;
            }
        }

        &.zerion {
            background: linear-gradient(80.97deg, #c5e0f6 6.89%, #e9efff 138.91%);

            span {
                color: #000 !important;
            }
        }

        &.rabby {
            background: linear-gradient(80.97deg, #dce6ff 6.89%, #e9efff 138.91%);

            span {
                color: #000 !important;
            }
        }

        &.frame {
            background: linear-gradient(80.97deg, #d3d7e0 6.89%, #b9beca 138.91%);

            span {
                color: #000 !important;
            }
        }
    }
}
