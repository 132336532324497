@import 'src/variables';

.navbar.Header {
    padding: 0;
    margin: 0;
    align-items: center;

    @media screen and (min-width: $media375) {
        height: 71px;
        min-height: 71px;
        z-index: 11;
        display: none;
    }

    @media screen and (min-width: $media1024) {
        z-index: 1;
        height: 74px;
        min-height: 74px;
        display: flex;
    }

    @media screen and (min-width: $media1440) {
        height: 80px;
        min-height: 80px;
    }

    @media screen and (min-width: $media1920) {
        height: 90px;
        min-height: 90px;
    }

    .inner {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media screen and (min-width: $media375) {
            width: 100%;
        }

        @media screen and (min-width: $media1024) {
            width: $media1024;
            margin: 0 auto;
        }

        @media screen and (min-width: $media1440) {
            width: $media1440;
            margin: 0 auto;
        }

        @media screen and (min-width: $media1920) {
            width: $media1920;
            margin: 0 auto;
        }
    }

    .navbar-brand {
        margin: 0;
        padding: 0;
        line-height: 0;

        @media screen and (min-width: $media375) {
        }

        @media screen and (min-width: $media1024) {
        }

        @media screen and (min-width: $media1440) {
            width: 220px;
        }

        @media screen and (min-width: $media1920) {
            width: 500px;
        }
    }

    .Logo {
        @media screen and (min-width: $media375) {
            width: 133px;
            height: 19.6px;
            margin-top: 0;
            margin-left: 21px;
            margin-right: 0;
        }

        @media screen and (min-width: $media1024) {
            width: 133px;
            height: 19.6px;
            margin-top: 0;
            margin-left: 40px;
            margin-right: 62px;
        }

        @media screen and (min-width: $media1440) {
            width: 133px;
            height: 19.6px;
            margin-top: 0;
            margin-left: 40px;
            margin-right: 62px;
        }

        @media screen and (min-width: $media1920) {
            width: 156px;
            height: 23px;
            margin-top: 0;
            margin-left: 47px;
            margin-right: 260px;
        }
    }

    .divider {
        @media screen and (min-width: $media375) {
            display: none;
        }

        @media screen and (min-width: $media1024) {
            display: block;
            margin-left: 21px;
            margin-right: 23px;
        }

        @media screen and (min-width: $media1440) {
            margin-left: 21px;
            margin-right: 23px;
        }
    }

    .WalletStatus {
        @media screen and (min-width: $media375) {
            position: absolute;
            right: 90px;
            top: 18px;
            height: 33px;
        }

        @media screen and (min-width: $media1024) {
            position: static;
            width: auto;
        }

        @media screen and (min-width: $media1440) {
            margin-left: 21px;
        }

        &__connected {
            .state,
            .address {
                @media screen and (min-width: $media1440) {
                    font-size: 12px;
                    line-height: 18px;
                    display: block;
                }
            }
        }
    }

    .ThemeSwitcher {
        @media screen and (min-width: $media375) {
            display: none;
        }
        @media screen and (min-width: $media1024) {
            display: flex;
            // margin-right: 25px;
        }
        @media screen and (min-width: $media1440) {
            // margin-right: 40px;
        }
    }

    .Disclaimer {
        @media screen and (min-width: $media1440) {
            margin-left: 45px;
        }
    }
}

.notifications-list {
    width: 350px;
    height: 350px;
    overflow: hidden;
    overflow-y: auto;

    .notification {
        display: flex;
        border-bottom: solid 1px #e7e7e7;
        padding: 20px 0;
        flex-wrap: wrap;

        .first-row,
        .second-row {
            width: 100%;
            display: flex;

            > div {
                width: 50%;
            }
        }

        .first-row {
            font-size: 12px;
            color: #636363;
        }

        .second-row {
            font-size: 14px;
            margin-top: 3px;

            a {
                color: #5b5959;
            }
        }

        .status {
            width: 5px;
            height: 5px;
            border-radius: 100%;

            &.COMPLETED {
                background-color: #85b900;
            }

            &.PENDING {
                background-color: #ffb100;
            }

            &.REMOVED {
                background-color: #f13308;
            }
        }
    }
}
