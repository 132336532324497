.dao-pool-voting-row {
    .vote-percentage-input {
        width: 100px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .titles {
        width: 140px;
    }

    .input {
        .max {
            background-color: transparent !important;
        }
    }
}

body.dark {
    #dao-container {
        .accordion-item {
            background-color: #2E2E2E;
            color: #fff;

            .accordion-header {
                background-color: transparent;

                button {
                    background-color: #434343 !important;
                }
            }

            .accordion-collapse {
                background-color: #2E2E2E;
            }

            .secondary {
                color: #BBBBBB;
            }

            img[src='zun-solo.svg'] {
                filter: invert(1);
            }
        }
    }
}