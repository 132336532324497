@import 'src/variables';

.InfoBlock {
    border-radius: 5px;
    position: relative;

    &_colorful {
        background: #f0f0f0;
    }

    @media screen and (min-width: $media375) {
        margin: 0 7px 15px 11px;
    }

    @media screen and (min-width: $media1024) {
        margin: 0 7px 15px 7px;
    }

    @media screen and (min-width: $media1440) {
        margin: 0 10px 14px 7px;
    }

    @media screen and (min-width: $media1920) {
        margin: 0 8.8px 16.4px 8.8px;
    }

    &__Icon {
        @media screen and (min-width: $media375) {
            margin-right: 10px;
            float: left;
            width: 11.6px;
            height: 12.3px;
            margin-left: 12px;
        }

        @media screen and (min-width: $media1024) {
            margin-left: 0;
            margin-right: 14px;
            float: left;
            width: 35px;
            height: 37px;
        }

        @media screen and (min-width: $media1440) {
            margin-right: 14px;
            float: left;
            width: 35px;
            height: 37px;
        }
    }

    &__title {
        color: #636363;

        @media screen and (min-width: $media375) {
            margin-left: 16px;
            margin-top: 12px;
            font-size: 12px;
            line-height: 16px;
        }

        @media screen and (min-width: $media1024) {
            margin-left: 15px;
            margin-top: 17px;
            font-size: 11px;
            line-height: 15px;
        }

        @media screen and (min-width: $media1440) {
            margin-left: 24px;
            margin-top: 20px;
            font-size: 12px;
            line-height: 16px;
        }

        @media screen and (min-width: $media1920) {
            margin-left: 28px;
            margin-top: 20px;
            font-size: 12px;
            line-height: 16px;
        }

        &.with_hint {
            display: flex;
            align-items: center;
        }
    }

    &__description {
        @media screen and (min-width: $media375) {
            margin-left: 16px;
            margin-top: 5px;
            font-size: 15px;
            line-height: 19px;
        }

        @media screen and (min-width: $media1024) {
            margin-left: 16px;
            margin-top: 10px;
            font-size: 13px;
            line-height: 18px;
        }

        @media screen and (min-width: $media1440) {
            margin-left: 24px;
            margin-top: 5px;
            font-size: 16px;
            line-height: 22px;
        }

        @media screen and (min-width: $media1920) {
            margin-left: 28px;
            margin-top: 10px;
            font-size: 16px;
            line-height: 22px;
        }

        &_color {
            color: $primary-color;

            @media screen and (min-width: $media375) {
                font-size: 16px;
                line-height: 22px;
            }

            @media screen and (min-width: $media1024) {
                font-size: 13px;
                line-height: 18px;
            }

            @media screen and (min-width: $media1440) {
                font-size: 16px;
                line-height: 22px;
            }

            @media screen and (min-width: $media1920) {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    &__hint {
        display: flex;
        align-items: center;
        padding-left: 10px;

        img {
            width: 8px;
            height: 8px;
        }
    }
}
