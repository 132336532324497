@import 'src/variables';

.ActionSelector {
    display: flex;
    align-items: center;
    @media screen and (min-width: $media375) {
        gap: 20px;
        margin-bottom: 30px;
        justify-content: center;
    }

    @media screen and (min-width: $media1024) {
        gap: 20px;
        margin-bottom: 30px;
        justify-content: flex-start;
    }

    @media screen and (min-width: $media1440) {
        gap: 26px;
        margin-bottom: 40px;
    }

    @media screen and (min-width: $media1920) {
    }

    &__Action {
        color: #000;
        cursor: pointer;
        text-decoration: none;
        text-align: center;
        border-bottom-color: transparent;

        &:hover {
            color: $primary-color;
            border-bottom-color: $primary-color;
        }

        @media screen and (min-width: $media375) {
            font-size: 16px;
            padding: 23px 0;
            border: none;
            width: 33%;
        }

        @media screen and (min-width: $media1024) {
            padding: 23px 46px;
            font-size: 12px;
            background-color: transparent;
            border-radius: 0;
            width: auto;
            padding: 0;
            border-bottom: solid 1px #000;
        }

        @media screen and (min-width: $media1440) {
            font-size: 14px;
        }

        @media screen and (min-width: $media1920) {
        }

        &__Active {
            border-bottom-color: transparent;
            border: solid 1px transparent;

            span {
                color: $primary-color !important;
            }
        }
    }
}
