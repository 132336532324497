@import 'src/variables';

.supporters-bar {
    bottom: 0;
    right: 0;
    padding: 10px 0;

    &[data-section='dashboard'] {
        .supporters-links {
            padding-right: 0;
        }
    }

    &[data-section='deposit'] {
        padding-top: 0;
        position: absolute;
        bottom: 5px;

        .supporters-links {
            padding-right: 0;
        }
    }

    &[data-section='uzd'] {
        position: static;
        overflow-x: auto;

        .supporters-links {
            padding-right: 0;
        }
    }

    &[data-section='analytics'] {
        .supporters-links {
            @media screen and (min-width: $media375) {
                padding-right: 0px;
                overflow-x: auto;
            }

            @media screen and (min-width: $media1024) {
                padding-right: 0;
            }
        }
    }

    .text-muted {
        font-size: 12px;
        margin-right: 20px;
    }

    .supporters-links {
        display: flex;
        gap: 10px;

        @media screen and (min-width: $media375) {
            margin-top: 20px;
            flex-wrap: wrap;
            justify-content: center;
        }

        @media screen and (min-width: $media1024) {
            margin-top: 0;
            justify-content: flex-end;
        }

        a {
            background: #f7f7f7;
            padding: 8px 25px 8px 12px;
            color: #999999;
            text-decoration: none;
            font-size: 12px;
            line-height: 14px;
            display: flex;
            align-items: center;
            gap: 10px;
            flex-wrap: nowrap;
            position: relative;
            border-radius: 20px;

            img {
                opacity: 0.5;
                height: 16px;
                width: 16px;
            }

            svg {
                position: absolute;
                top: 8px;
                right: 13px;
                width: 6px;
                height: 6px;
            }

            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .row {
        .col {
            @media screen and (min-width: $media375) {
                flex-direction: column;
            }

            @media screen and (min-width: $media1024) {
                flex-direction: row;
            }
        }
    }
}
