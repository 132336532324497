$border-radius: 20px;
$border-radius17: 17px;

$media1920: 1920px;
$media1600: 1600px;
$media1440: 1440px;
$media1200: 1200px;
$media1024: 1024px;
$media375: 360px;
$media435: 435px;
$media577: 577px;

$box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

$on-hover-color: #de4300;
$on-hover-secondary-color: #e6e6e6;

$primary-color: #fb7501;
$primary-bg-gradient: linear-gradient(78.51deg, #f7f7f7 12.17%, #f6f6f6 65.32%);
$muted-color: #767676;

$primary-panel-bg: #e4e4e4;
$secondary-panel-bg: #e4e4e4;

/* Black theme */

$block-bg-black-color: #2f2f2f;
$block-fg-black-color: #3a3a3a;
$dark-panel-bg: #2a2a2a;
$dark-border-color: #333333;
$dark-muted-text-color: #a1a1a1;

$--bs-body-bg: #fffcf5;

$btn-secondary-color: #f1f1f1;
